import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form } from "react-bootstrap";
import axios from "axios";
import "../styles/talent_sign_in.scss";
import TwitterIcon from "@material-ui/icons/Twitter";
import { AiFillGoogleCircle } from "@react-icons/all-files/ai/AiFillGoogleCircle";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import logoImg from "../image/smokeshow_red.png";
import { AiOutlineEyeInvisible } from "@react-icons/all-files/ai/AiOutlineEyeInvisible";
import { AiOutlineEye } from "@react-icons/all-files/ai/AiOutlineEye";
import IconButton from "@material-ui/core/IconButton";
import { Swiper, SwiperSlide } from "swiper/react";
import slide2 from "../image/slide-11.png";
import slide4 from "../image/video6.png";
import googleimg from "../image/google-symbol.png";
// Import Swiper styles
import "swiper/swiper.min.css";
// import Swiper core and required modules
import SwiperCore, { Autoplay } from "swiper";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../javascript/image/smokeshow_blue.png";

// install Swiper modules
SwiperCore.use([Autoplay]);

export default function Login({ Click }) {
  const history = useNavigate();
  const [encrypted_password, setPassword] = useState("");
  const [encrypted_confirmation, setConfirmation] = useState("");
  const [email, setEmail] = useState("");
  const [sessionType, setSessionType] = useState("Login");
  const [alertShow, setAlertShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);

  const submitSignUpForm = async (event) => {
    event.preventDefault();
    const user = {
      password: encrypted_password,
      password_confirmation: encrypted_password,
      email: email,
      user_type: "consumer",
    };

    setAlertShow(false);

    try {
      const url = "/users";
      const token = document.querySelector('meta[name="csrf-token"]').content;
      const payload = JSON.stringify({ user });
      const response = await axios.post(url, payload, {
        headers: {
          "content-type": "application/json",
          "X-CSRF-Token": token,
        },
      });
      window.location.href = "/";
    } catch (e) {
      console.log(e);
    }
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowCnfPassword = () => {
    setShowCnfPassword((show) => !show);
  };

  const socialDesign = (
    <>
      <a
        href='/users/auth/twitter?csrf=OxB30R029ae9badbf98ac767a8b12d0d7a7e12d2'
        className='g-btn m-rounded m-twitter'>
        <TwitterIcon />
        <span>Sign in with twitter</span>
      </a>

      <a
        href='/users/auth/google_oauth2?csrf=OxB30R029ae9badbf98ac767a8b12d0d7a7e12d2'
        className='g-btn m-rounded m-google'>
        {/* <AiFillGoogleCircle size={27} /> */}
        <div className='google-img'>
          <img
            src={googleimg}
            alt='google'
          />
        </div>
        <span>Sign in with google</span>
      </a>
    </>
  );

  const sliderSwiper = (
    <>
      <div className='guest_swiper_wrapper'>
        <Swiper
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          className='mySwiper'>
          <SwiperSlide>
            <img
              src={slide2}
              alt='no image'
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={slide4}
              alt='no image'
              style={{ minHight: "547px" }}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
  const submitLoginForm = async (event) => {
    event.preventDefault();
    const user = {
      password: encrypted_password,
      email: email,
    };

    try {
      const url = "/users/sign_in";
      const token = document.querySelector('meta[name="csrf-token"]').content;
      const payload = JSON.stringify({ user });
      const response = await axios.post(url, payload, {
        headers: {
          "content-type": "application/json",
          "X-CSRF-Token": token,
        },
      });
      if (response.data.status == 201) {
        window.location.href = "/";
        toast("Success: Account logged in");
      }
    } catch (e) {
      setAlertShow(true);
      toast("Failed: Account Login");
    }
  };

  if (sessionType === "Login") {
    return (
      <>
        <div className='talent_sign_in-container'>
          <div className='row login-container'>
            <div
              id='talent-form-container'
              className='col-lg-4 col-md-12 offset-lg-3 col-xs-12'>
              <div
                style={
                  {
                    //  marginBottom: "20px",
                    //  display: "flex",
                    //  alignItems: "center",
                    //  justifyContent: "center",
                    //  fontWeight: "bold",
                  }
                }
                className='talent-form-header'>
                {/* <h4 style={{ fontWeight: "bold" }}> Login</h4> */}
                <img
                  style={{ margin: "20px auto" }}
                  src={logo}
                  width={200}
                  alt='Smokeshow Logo'
                />
              </div>
              <Form style={{ padding: "0px 20px" }}>
                <TextField
                  className='outlined-basic'
                  type='email'
                  label='E-mail'
                  variant='outlined'
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />

                <TextField
                  className='outlined-basic'
                  type={showPassword ? "text" : "password"}
                  label='Password'
                  variant='outlined'
                  value={encrypted_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleShowPassword}
                          edge='end'>
                          {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <div className='sign_in_footer'>
                  <div style={{ width: "100%" }}>
                    <Button
                      style={{ width: "100%" }}
                      className='g-btn m-rounded m-flex m-lg m-login-btn'
                      onClick={submitLoginForm}>
                      {sessionType}
                    </Button>
                  </div>
                </div>
                <div
                  className='forgot-password'
                  style={{ textAlign: "center" }}>
                  <p>
                    <button
                      type='button'
                      className='g-btn m-flat m-toggle-btn '
                      onClick={() => setSessionType("Sign Up")}>
                      Create an account
                    </button>
                  </p>
                  <span style={{ color: "#9999" }}>|</span>
                  <p>
                    <button
                      type='button'
                      className='g-btn m-flat m-toggle-btn '
                      onClick={() => setSessionType("Sign Up")}>
                      Forgot Password?
                    </button>
                  </p>
                </div>
                <div className='separator'>or</div>
                {socialDesign}

                <div
                  className='whitout-login'
                  onClick={Click}>
                  Continue without account
                </div>
                <div className='b-loginreg__form__issues'>
                  Visit <a href='/help/1/4'>Help Center</a> for additional help if you are
                  unable to log in with your existing Account
                </div>
                {/* <div style={{ textAlign: "center" }}>
                  <h3>Don't have an account yet?</h3>
                  <p>
                    <button
                      type='button'
                      className='g-btn m-flat m-toggle-btn '
                      onClick={() => setSessionType("Sign Up")}>
                      {" "}
                      Sign up for Smokeshow
                    </button>
                  </p>
                </div> */}
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className='talent_sign_in-container'>
        <div className='row signup-container'>
          <div
            id='talent-form-container'
            className='col-lg-4 col-md-12 offset-lg-3 col-xs-12'>
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
              }}>
              {/* <h4 style={{ fontWeight: "bold" }}>Create new account</h4> */}
              <img
                src={logo}
                width={200}
                alt='Smokeshow Logo'
              />
            </div>
            <Form className='sign_up_form'>
              <TextField
                className='outlined-basic'
                type='email'
                label='E-mail'
                variant='outlined'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />

              <TextField
                className='outlined-basic'
                type={showPassword ? "text" : "password"}
                label='Password'
                variant='outlined'
                value={encrypted_password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={handleShowPassword}
                        edge='end'>
                        {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => setPassword(event.target.value)}
              />

              <div className='sign_up_footer'>
                <div style={{ width: "100%" }}>
                  <Button
                    style={{ width: "100%" }}
                    className='g-btn m-rounded m-flex m-lg m-login-btn'
                    onClick={submitSignUpForm}>
                    {sessionType}
                  </Button>
                </div>
              </div>

              <div className='g-input__wrapper m-reset-bottom-gap'>
                <span className='b-loginreg__form__signing-terms signup-term'>
                  By signing up you agree to our <a href='/terms'>Terms of Service</a> and{" "}
                  <a href='/privacy'>Privacy Policy</a>, and confirm that you are at least
                  18 years old.
                </span>
              </div>

              <div className='sign-in-container'>
                <p className=''>Already have an account?</p>
                <p>
                  <button
                    onClick={() => setSessionType("Login")}
                    type='button'
                    className='g-btn m-flat m-toggle-btn'>
                    Login
                  </button>
                </p>
              </div>

              <div className='separator'>or</div>
              {socialDesign}
            </Form>
            <div
              className='whitout-login'
              onClick={Click}>
              Continue without account
            </div>
          </div>
        </div>
      </div>
    );
  }
}
