import React, { useState, useEffect } from 'react';

import { isMobile } from 'react-device-detect';
import { debounce } from '../../helper/utils';

export default () => {
  const [width, setWidth] = useState(768);
  const [screenDetail, setScreenDetail] = useState({
    small: false,
    smallest: false
  });

  useEffect(() => {
    // change width from the state object
    setScreenDetail({
      small: window.innerWidth < width || isMobile,
      smallest: window.innerWidth < 768 || isMobile
    });

    const updateDimensions = () => {
      console.log('width', window.innerWidth, 'isMobile', isMobile);
      setScreenDetail({
        small: window.innerWidth < width,
        smallest: window.innerWidth < 768
      });
    };
    // set resize listener
    window.addEventListener('resize', updateDimensions);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', updateDimensions);
    };
  }, [width, isMobile]);

  return [screenDetail, setWidth];
};
