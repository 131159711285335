import { withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { styleSheet } from "./userCard";
import defaultPic from "../../image/avatar_default.png";
import { mediaQuery } from "../hooks/mediaQuery";

const UserCard = (props) => {
 const { isXs } = mediaQuery();

 const {
  classes,
  imgSrc,
  username,
  onClick,
  button,
  desc,
  onMobile,
  postTime,
 } = props;
 return (
  <>
   <div className={classes.root}>
    <div>
     <div className={classes.userData}>
      <div>
       <img
        className={classes.avatarImg}
        // src={imgSrc|| defaultPic}
        src={defaultPic}
        alt='User Profile'
        onClick={onClick}
       />
      </div>
      <div>
       <h5
        className={classes.userText}
        onClick={onClick}
        title={username?.substring(0, 10) + "..." || null}>
        {/* {username?.substring(0, 10) || username} */}
        {username?.length > 20 && isXs
         ? username?.substring(0, 15) + ".."
         : username}

        {/* <span
         style={{
          fontSize: "11px",
          textTransform: "lowercase",
          color: "#cccccc",
          marginLeft: "0.4rem",
          fontWeight: "400",
         }}>
         - 5 mintutes ago
        </span> */}
       </h5>
       {postTime ? (
        <p
         className={classes.postTime}
         title={postTime || null}>
         {postTime?.substring(0, 20) || null}
         {postTime?.length > 20 ? "..." : ""}
        </p>
       ) : null}
      </div>
     </div>
    </div>
    {onMobile ? (
     <></>
    ) : button ? (
     <div className={classes.btnRight}> {button}</div>
    ) : null}
   </div>
   {onMobile ? (
    <></>
   ) : desc ? (
    <div
     className={classes.postDesc}
     title={desc || null}>
     <p dangerouslySetInnerHTML={{ __html: desc !== "" ? desc : null }}></p>
     {/*{desc?.length > 200 ? "..." : ""}*/}
    </div>
   ) : null}
  </>
 );
};

export default withStyles(styleSheet, { name: "userCard" })(UserCard);
