import React, { useState, useEffect } from "react";
import "./style.scss";
import CommenArea from "./CommentArea";

const CommentModalMobile = ({
  isCommentShow,
  setIsCommentShowAction,
  currentVideo,
  currentPostId,
  commentType,
}) => {
  const [modalState, setModalState] = useState(false);
  console.log("?????????????????????????????????????", commentType)
  React.useMemo(() => {
    setIsCommentShowAction(false);
    setModalState(false);
  }, [currentVideo]);

  useEffect(() => {
    setModalState(isCommentShow);
  }, [isCommentShow]);

  return (
    <div
      className='commentModalMobile'
      style={{
        maxHeight: modalState ? "500px" : "0px",
      }}>
      <div
        style={{ position: "relative", height: "30px" }}
        onTouchMove={() => {
          setModalState(false);
          setIsCommentShowAction(false);
        }}
        onClick={() => {
          setModalState(false);
          setIsCommentShowAction(false);
        }}
        className='topLine_'>
        <span></span>
      </div>
      <CommenArea commentType={commentType} currentPostId={currentPostId} />
    </div>
  );
};

export default CommentModalMobile;
