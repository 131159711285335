import React from "react";
import { Grid } from "@material-ui/core";
import TalentSignIn from "../talent_sign_in";
import logo from "../../../javascript/image/smokeshow_blue.png";
import LoginPageFooter from "./Components/LoginPageFooter";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import mock from "../../../javascript/image/home-phones-2x.png";
import "./login.scss";
import { mediaQuery } from "../hooks/mediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiIconButton-colorInherit": {
      position: "absolute",
      top: "10px",
      right: "10px",
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "transparent",
    },

    "& .MuiDialog-paperFullScreen": {
      background: "linear-gradient(90deg,#f5f5f5 52%,#ffffff 50.01%)",
      [theme.breakpoints.down("sm")]: {
        background: "white",
      },
    },
    "& .MuiSvgIcon-root": {
      height: "40px",
      width: "40px",
    },
  },
  mt: {
    marginTop: "60px",
  },
}));
export default function Login(props) {
  //  const classes = defaultStyles();
  const classes = useStyles();
  const { isSmUp } = mediaQuery();
  const { show, handleClose } = props;

  return (
    <Dialog
      fullScreen
      open={show}
      onClose={handleClose}
      className={classes.root}>
      <DialogContent>
        {/* <IconButton
     edge="start"
     color="inherit"
     onClick={() => handleClose(false)}
     aria-label="close"
    >
     <CloseIcon />
    </IconButton> */}
        <div className='signin-up-container'>
          <Grid container>
            {isSmUp ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}>
                {/* <div className='logo'>
                <div className='logo-content'>
                  <img
                    src={mock}
                    alt='logo'
                  />
                  <h2>Sign up to support your favorite creators</h2>
                </div>
              </div> */}
                <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                  <img
                    src={mock}
                    style={{ width: "100%" }}
                    alt='mock'
                  />
                </div>
              </Grid>
            ) : null}

            <Grid
              item
              xs={12}
              sm={12}
              md={6}>
              <TalentSignIn Click={() => handleClose(false)} />
            </Grid>
          </Grid>
        </div>

        <LoginPageFooter />
      </DialogContent>
    </Dialog>
  );
}
