import React, { useState, useContext, lazy, Suspense } from "react";
import { GlobalContext } from "./app";
import { useLocation } from "react-router-dom";
import axios from "axios";
import logoImg from "../image/smokeshow.png";
import { BsSearch } from "react-icons-all-files/bs/BsSearch";
import { RiUser3Line } from "react-icons-all-files/ri/RiUser3Line";
import { RiMessengerLine } from "react-icons-all-files/ri/RiMessengerLine";
import { RiHome4Line } from "react-icons-all-files/ri/RiHome4Line";
import { RiAddBoxLine } from "react-icons-all-files/ri/RiAddBoxLine";
import { RiSearchLine } from "react-icons-all-files/ri/RiSearchLine";
import { TiHome } from "react-icons-all-files/ti/TiHome";
import { MdOutlineFavoriteBorder } from "react-icons-all-files/md/MdOutlineFavoriteBorder";
import { Navbar, Nav, Form, Alert, Dropdown } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import TipArrow from "../image/tip_arrow_white.svg";
import { mediaQuery } from "./hooks/mediaQuery";

// Lazy loading Material-UI components
const Toolbar = lazy(() => import("@material-ui/core/Toolbar"));
const UiButton = lazy(() => import("@material-ui/core/Button"));
const Divider = lazy(() => import("@material-ui/core/Divider"));
const Drawer = lazy(() => import("@material-ui/core/Drawer"));
const List = lazy(() => import("@material-ui/core/List"));
const ListItem = lazy(() => import("@material-ui/core/ListItem"));
const Link = lazy(() => import("@material-ui/core/Link"));
const ReorderIcon = lazy(() => import("@material-ui/icons/Reorder"));
const AccountCircle = lazy(() => import("@material-ui/icons/AccountCircle"));
const Close = lazy(() => import("@material-ui/icons/Close"));
const ExitToApp = lazy(() => import("@material-ui/icons/ExitToApp"));
const Help = lazy(() => import("@material-ui/icons/Help"));
const SupervisedUserCircle = lazy(() =>
  import("@material-ui/icons/SupervisedUserCircle")
);

// Lazy loading components from your project
const SearchResults = lazy(() => import("./SearchResult/search"));
const FullScreenDialog = lazy(() => import("./createPost/dialog"));
const MiniDrawer = lazy(() => import("./sidebar/NewDrawer"));

import defaultImg from "../image/avatar_default.png";
import "../styles/navbar.scss";
import "../styles/app.scss";
import LoaderComponent from "../helper/loader";

const MobileNavTab = ({
  activeFnc,
  index,
  activeIndex,
  tabData,
  actionFnc,
}) => {
  const history = useNavigate();

  const setActiveFnc = () => {
    activeFnc(index);
    history(tabData?.path);
    if (actionFnc) {
      actionFnc();
    }
  };
  return (
    <div onClick={setActiveFnc}>
      {activeIndex === index || tabData?.path === history?.location?.pathname
        ? tabData?.actionIcon
        : tabData?.icon}
    </div>
  );
};

export default function NavBar(props) {
  const { logoutFnc, handleShow, currentUser, open, drawerOpen } = props;
  const history = useNavigate();
  const [alertShow, setAlertShow] = useState(false);
  const [profilePopup, setProfilePopup] = useState(false);
  const [loginAlertShow, setLoginAlertShow] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [state, setState] = useState({
    left: false,
  });
  const { setIsSelectMessages } = useContext(GlobalContext);
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const profileUrl = currentUser?.props ? currentUser?.props.profileUrl : "";
  const profileImg = profileUrl?.length ? profileUrl : defaultImg;
  const [postModal, setPostModal] = useState(false);

  const toggleDrawer = (open) => {
    setState({ ...state, left: open });
  };

  const mobileNavItemList = [
    {
      icon: <RiHome4Line size={30} />,
      actionIcon: (
        <RiHome4Line
          size={30}
          color='#4285F4'
        />
      ),
      path: "/",
    },
    {
      path: "/search",
      icon: <RiSearchLine size={30} />,
      actionIcon: (
        <RiSearchLine
          size={30}
          color='#4285F4'
        />
      ),
    },
    {
      path: "/post",
      icon: <RiAddBoxLine size={30} />,
      // actionFnc: (e) => {
      //   setPostModal(!postModal);
      // },
      actionIcon: (
        <RiAddBoxLine
          size={30}
          color='#4285F4'
        />
      ),
    },
    {
      path: "/messages",
      icon: <RiMessengerLine size={30} />,
      actionIcon: (
        <RiMessengerLine
          size={30}
          color='#4285F4'
        />
      ),
    },
    {
      icon: (
        <RiUser3Line
          size={30}
          onClick={() => toggleDrawer(true)}
        />
      ),
      actionIcon: (
        <RiUser3Line
          size={30}
          onClick={() => toggleDrawer(true)}
        />
      ),
    },
  ];
  const [showNotification, setNotificationView] = useState(false);
  const location = useLocation();
  const { isXs, isSm } = mediaQuery();

  const redirectToHome = () => {
    setNotificationView(false);
    setProfilePopup(false);
    history(`/`);
  };

  const redirectToMessages = () => {
    history(`/messages`);
    setNotificationView(false);
    setProfilePopup(false);
  };

  const setNotification = () => {
    setNotificationView(!showNotification);
    setProfilePopup(false);
  };

  const setSearchBar = () => {
    setNotificationView(false);
    setProfilePopup(false);
    setIsSearchModalOpen(!isSearchModalOpen);
  };

  const redirectToSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    setNotificationView(false);
    setProfilePopup(false);
    if (searchText.length) {
      history(`/search?searchText=${searchText}`);
    } else {
      return;
    }
  };

  const fetchSearchResults = async (value) => {
    setSearchText(value);
    try {
      const searchUrl = `/searching?search=${value}`;
      const data = await axios.get(searchUrl);
      if (data.data && data.data.length > 0 && value != "") {
        setIsSearchModalOpen(true);
        setSearchResult(data.data.slice(0, 5));
        if (data.data) {
          setIsSearchModalOpen(true);
          setSearchResult(data.data);
        } else {
          setSearchResult([]);
        }
        return data;
      } else {
        setSearchResult([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios
      .delete("/users/sign_out", {
        headers: {
          "content-type": "application/json",
          "X-CSRF-Token": token,
        },
      })
      .then(function (response) {
        logoutFnc();
        setAlertShow(true);
        window.location.href = "/";
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onClickSearch = (value) => {
    setIsSearchModalOpen(false);
  };

  const modalAction = () => {
    setPostModal(!postModal);
  };

  const [navIndex, setNavIndex] = useState(0);
  const activeFnc = (index) => {
    setNavIndex(index);
  };

  const linkOpen = (val) => {
    history(val);
  };

  return (
    <>
      <Suspense
        fallback={
          <div>
            <LoaderComponent loaderState />
          </div>
        }>
        <div
          onClick={() =>
            profilePopup || showNotification
              ? (() => {
                  setProfilePopup(false);
                  setNotificationView(false);
                })()
              : null
          }
          className='_navbar_toolbar__'>
          {postModal && (
            <FullScreenDialog
              show={postModal}
              handleClose={modalAction}
            />
          )}
          {!props.currentUser ? (
            !isXs ? (
              <>
                <Toolbar>
                  <div className='navbar_container'>
                    <Navbar
                      className='justify-content-between nav-bar-header'
                      expand='lg'
                      style={{ height: "100%" }}>
                      <Navbar.Collapse id='basic-navbar-nav'>
                        <div id='left-navbar'>
                          <div
                            className='small-device'
                            style={{ cursor: "pointer", paddingTop: "3px" }}
                            onClick={() => toggleDrawer(true)}>
                            <ReorderIcon fontSize={"large"} />
                          </div>
                          <Navbar.Brand
                            onClick={() => redirectToHome()}
                            className='logo-link'>
                            <div className='logo'>
                              <img
                                className={"logoImg"}
                                alt='no logo'
                                src={logoImg}
                              />
                            </div>
                          </Navbar.Brand>
                        </div>
                        <div
                          className='search-container large-device'
                          id='search-bar'>
                          <Form
                            inline
                            className='search-form'
                            onSubmit={(e) => redirectToSearch(e)}>
                            <UiButton
                              type='submit'
                              className='search-btn'>
                              <BsSearch />
                            </UiButton>
                            <input
                              value={searchText}
                              onChange={(e) =>
                                fetchSearchResults(e.target.value)
                              }
                              type='text'
                              placeholder='Search here'
                              className='search-input mr-sm-2'
                            />
                          </Form>
                          {searchResult.length > 0 && isSearchModalOpen && (
                            <div className='search-div content-wrap inbox-wrap-enter-done'>
                              <div className='inbox'>
                                <SearchResults
                                  srchresult={searchResult}
                                  onClickSearch={(val) => onClickSearch(val)}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <Navbar.Collapse
                          id='basic-navbar-nav'
                          className=''>
                          <Nav id='right-nav-bar'>
                            <Nav.Link className='upload-btn-link'>
                              {" "}
                              <Button
                                variant='link'
                                className='upload-btn'
                                onClick={handleShow}>
                                Upload
                              </Button>
                            </Nav.Link>
                            <Nav.Link>
                              <Button
                                variant='danger'
                                className='login-btn'
                                onClick={handleShow}>
                                Log in
                              </Button>
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                </Toolbar>
                <Alert
                  show={alertShow}
                  variant='success'>
                  Logout was successful
                </Alert>
                <Alert
                  show={loginAlertShow}
                  variant='success'>
                  Login was successful
                </Alert>
              </>
            ) : null
          ) : (
            <>
              <div
                onClick={setNotification}
                className={showNotification ? "outside-click" : ""}></div>
              <div
                onClick={setSearchBar}
                className={isSearchModalOpen ? "outside-click" : ""}></div>
              <div>
                <>
                  <div className='bottomNav_container'>
                    {/* {isXs && location?.pathname?.indexOf("/messages") === -1 ? ( */}
                    {isXs || isSm ? (
                      <div className='bottomNavIcons'>
                        {mobileNavItemList?.length &&
                          mobileNavItemList.map((ii, zz) => (
                            <MobileNavTab
                              key={zz + 1}
                              activeFnc={activeFnc}
                              activeIndex={navIndex}
                              index={zz}
                              actionFnc={ii?.actionFnc}
                              tabData={ii}
                            />
                          ))}
                      </div>
                    ) : null}

                    <Drawer
                      anchor={"left"}
                      open={state["left"]}
                      onClose={() => toggleDrawer(false)}>
                      <Suspense fallback={<div>Loading...4</div>}>
                        <MiniDrawer
                          drawerOpen={drawerOpen}
                          open={open}
                          noBurgerIcon
                          logoutFnc={logoutFnc}
                          handleAction={handleShow}
                          currentUser={currentUser}
                          toggleDrawer={toggleDrawer}
                        />
                      </Suspense>
                    </Drawer>
                  </div>
                </>
                {isXs ? null : (
                  <div className='navbar_container'>
                    <Navbar
                      className='justify-content-between nav-bar-header'
                      expand='lg'
                      style={{ height: "100%" }}>
                      <Navbar.Collapse id='basic-navbar-nav'>
                        <div id='left-navbar'>
                          <div
                            className='small-device'
                            style={{ cursor: "pointer", paddingTop: "3px" }}
                            onClick={() => toggleDrawer(true)}>
                            <ReorderIcon fontSize={"large"} />
                          </div>
                          <Navbar.Brand
                            onClick={() => redirectToHome()}
                            className='logo-link'>
                            <div className='logo'>
                              <img
                                className={"logoImg"}
                                alt='no logo'
                                src={logoImg}
                              />
                            </div>
                          </Navbar.Brand>
                        </div>
                        <div
                          className='search-container large-device'
                          id='search-bar'>
                          <Form
                            inline
                            className='search-form'
                            onSubmit={(e) => redirectToSearch(e)}>
                            <UiButton
                              type='submit'
                              className='search-btn'>
                              <BsSearch />
                            </UiButton>
                            <input
                              value={searchText}
                              onChange={(e) =>
                                fetchSearchResults(e.target.value)
                              }
                              type='text'
                              placeholder='Search here'
                              className='search-input mr-sm-2'
                            />
                          </Form>
                          {searchResult.length > 0 && isSearchModalOpen && (
                            <div className='search-div content-wrap inbox-wrap-enter-done'>
                              <div className='inbox'>
                                <SearchResults
                                  srchresult={searchResult}
                                  onClickSearch={(val) => onClickSearch(val)}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <Nav id='right-nav-bar'>
                          <Nav.Link
                            onClick={redirectToHome}
                            className={
                              location?.pathname === "/"
                                ? "nave-active-state"
                                : ""
                            }>
                            <TiHome size={25} />
                          </Nav.Link>
                          <Nav.Link
                            onClick={redirectToMessages}
                            className={
                              location?.pathname === "/messages"
                                ? "nave-active-state"
                                : ""
                            }>
                            <RiMessengerLine
                              id='message-button'
                              onClick={() => setIsSelectMessages(false)}
                              size={25}
                            />
                          </Nav.Link>
                          <Nav.Link
                            onClick={() => {
                              setPostModal(!postModal);
                              setNotificationView(false);
                              setProfilePopup(false);
                            }}>
                            <RiAddBoxLine size={25} />
                          </Nav.Link>
                          <Nav.Link
                            onClick={setNotification}
                            className={
                              showNotification ? "nave-active-state" : ""
                            }>
                            <MdOutlineFavoriteBorder size={27} />
                          </Nav.Link>

                          {profilePopup ? (
                            <div className='notification-div content-wrap inbox-wrap-enter-done setting_dropup'>
                              <img
                                src={TipArrow}
                                className='content-wrap-arrow'
                              />
                              <div className='inbox'>
                                <div className='inbox-content-header'>
                                  <div className='inbox-content-notification'>
                                    Good morning, Hady!
                                  </div>
                                  <span className='close_settings_'>
                                    <Button
                                      color='primary'
                                      variant='text'
                                      onClick={() =>
                                        setProfilePopup(!profilePopup)
                                      }>
                                      <Close />
                                    </Button>
                                  </span>
                                </div>
                                <List>
                                  <ListItem
                                    className='p-0 link-style'
                                    component={Link}
                                    onClick={() =>
                                      linkOpen("/settings/profile")
                                    }>
                                    <AccountCircle />
                                    <span className=' pl-2 drawer-fonts-style'>
                                      Profile settings
                                    </span>
                                  </ListItem>
                                  <ListItem
                                    className='p-0 link-style'
                                    component={Link}
                                    onClick={() =>
                                      linkOpen("/settings/account")
                                    }>
                                    <SupervisedUserCircle />
                                    <span className=' pl-2 pb-2 drawer-fonts-style'>
                                      Account settings
                                    </span>
                                  </ListItem>

                                  <Divider className={"mTop-20"} />

                                  {process.env.ENV_SETTING != "PROD" && (
                                    <ListItem
                                      className='p-0 link-style'
                                      component={Link}>
                                      <Help />
                                      <span className=' pl-2 drawer-fonts-style'>
                                        Help and Support
                                      </span>
                                    </ListItem>
                                  )}

                                  <ListItem
                                    className='p-0 link-style'
                                    component={Link}
                                    onClick={() => logout()}>
                                    <ExitToApp />
                                    <span className=' pl-2 drawer-fonts-style'>
                                      Logout
                                    </span>
                                  </ListItem>
                                </List>
                              </div>
                            </div>
                          ) : null}
                          <Dropdown className='user-menu-dropdown'>
                            <div
                              className='user-menu'
                              onClick={() => setProfilePopup(!profilePopup)}>
                              <img
                                src={profileImg}
                                style={{ width: "25px" }}
                                alt=''
                                className='rounded-circle'
                              />
                            </div>
                          </Dropdown>
                        </Nav>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                )}
              </div>
              <Alert
                show={alertShow}
                variant='success'>
                Logout was successful
              </Alert>
              <Alert
                show={loginAlertShow}
                variant='success'>
                Login was successful
              </Alert>
            </>
          )}
        </div>
      </Suspense>
    </>
  );
}
