import React, { useContext } from "react";
import { Context as GeneralContext } from "../components/context/GeneralContext";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoaderComponent = (props) => {
  const { state } = useContext(GeneralContext);
  return (
    <>
      {props.loaderState ? (
        <div className='loader'>
          <div className='overlay'></div>
          <div className='loader-spinner'>
            <CircularProgress
              color='primary'
              size={84}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}{" "}
    </>
  );
};

export default LoaderComponent;
