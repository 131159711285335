import React, { useState } from "react";
import NavBar from "./navbar";
import Login from "./LoginSignup/Login";
import "bootstrap/dist/css/bootstrap.css";

import { BottomNavigation } from "@material-ui/core";
import { mediaQuery } from "./hooks/mediaQuery";

const Home = ({ show, handleAction, props, sideDrawerState, logUserOut }) => {
 const { isXs, isSm } = mediaQuery();
 const [state, setState] = useState({
  right: false,
 });

 const [value, setValue] = useState("recents");

 const toggleDrawer = (open) => (event) => {
  if (
   event.type === "keydown" &&
   (event.key === "Tab" || event.key === "Shift")
  ) {
   return;
  }

  setState({ ...state, right: open });
 };

 return (
  <div>
   {isXs || isSm ? (
    <NavBar
     handleShow={handleAction}
     currentUser={props.currentUser}
     onClick={toggleDrawer(false)}
     onKeyDown={toggleDrawer(false)}
     sideDrawerState={sideDrawerState}
     toggleftDrawer={toggleDrawer}
     open={open}
     logoutFnc={props.logoutFnc}
    />
   ) : null}
   <Login show={show} handleClose={handleAction} />
   {!props?.currentUser && (
    <>
     <BottomNavigation value={value} className={"bottomNavigation"}>
      <button onClick={handleAction} className="login-btn btn btn-danger">
       Login
      </button>
     </BottomNavigation>
    </>
   )}
  </div>
 );
};

export default Home;
