import "./style.scss";
import React, { useEffect, useState, memo } from "react";
import Image from "react-bootstrap/Image";
import defaultProfileIcon from "../../../../image/avatar_default.png";
import { BsHeart } from "@react-icons/all-files/bs/BsHeart";
import { BsThreeDots } from "@react-icons/all-files/bs/BsThreeDots";
import TimeAgo from "timeago-react";
import Dropdown from "react-bootstrap/Dropdown";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";

const CommentComp = (props) => {
  const { comment } = props;
  const [viewReplies, toggleReplies] = useState(false);
  const [toggleReply, setToggleReply] = useState(false);

  return (
    <div className='comment-container'>
      <div className='d-flex'>
        <Image
          className='profile_image'
          src={
            comment.profile_picture
              ? comment.profile_picture
              : defaultProfileIcon
          }
          roundedCircle
        />
        <div className='comment_details_root'>
          <span id='username-text'>{comment.user_name}</span>
          <p className='comment-body'>{comment.body}</p>

          <div>
            <Dropdown className='reply-dropdown w-100'>
              <div>
                <div className='reply-date-container align-items-center'>
                  <div className='d-flex'>
                    <span className='reply-text'>
                      <TimeAgo datetime={comment.created_at} />
                    </span>
                    <Dropdown.Toggle
                      variant='success'
                      id='dropdown-more'
                      style={{ paddingLeft: "16px" }}>
                      <span className='reply-text'>Reply</span>
                    </Dropdown.Toggle>
                    {props?.currentUser?.id == comment.user_id ? (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='success'
                          id='dropdown-basic'
                          style={{ marginLeft: "16px" }}>
                          <BsThreeDots
                            size={22}
                            className='color-gray-svg'
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              props.handleCommentDelete(
                                comment.id,
                                "",
                                "comment"
                              )
                            }>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className='comment_like_'>
                    {comment.like_count}
                    <BsHeart
                      onClick={() =>
                        props.commentLike(
                          comment.id,
                          "Comment",
                          comment.liked,
                          comment.like_id
                        )
                      }
                      size={20}
                      className='color-gray-svg'
                      style={{
                        fill: comment.liked == true && "red",
                      }}
                    />
                  </div>
                </div>
              </div>
              <Dropdown.Menu className='reply d-flex align-items-center mt-2'>
                <input
                  value={props.reply}
                  onChange={(e) => props.setReply(e.target.value)}
                  type='text'
                  placeholder='Reply...'
                  className='form-control ml-0 rounded'
                />
                <button
                  disabled={!props.reply}
                  onClick={() => props.commentReply(comment.id, "Comment")}
                  className='btn btn-outline-dark'>
                  Post
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {comment.replies.length > 0 && (
            <div className='reply-container'>
              <div className='d-flex'>
                <Image
                  className='reply-profile_image'
                  src={
                    comment.replies[0].profile_picture
                      ? comment.replies[0].profile_picture
                      : defaultProfileIcon
                  }
                  roundedCircl
                />
                <div className='comment_details_root'>
                  <span id='username-text'>{comment.replies[0].user_name}</span>
                  <p className='comment-body'>{comment.replies[0].body}</p>

                  <div className=''>
                    <Dropdown className='reply-nested-comment'>
                      <div className='reply-date-container align-items-center'>
                        <div className='d-flex'>
                          <span
                            className='reply-text'
                            style={{ paddingLeft: "0px" }}>
                            <TimeAgo datetime={comment.replies[0].created_at} />
                          </span>
                          <Dropdown.Toggle
                            variant='success'
                            id='dropdown-reply-nested'>
                            <span
                              className='reply-text'
                              style={{ paddingLeft: "16px" }}>
                              Reply
                            </span>
                          </Dropdown.Toggle>
                          {props.currentUser?.id ==
                          comment.replies[0].user_id ? (
                            <Dropdown>
                              <Dropdown.Toggle
                                variant='success'
                                id='dropdown-basic'
                                style={{ marginLeft: "16px" }}>
                                <BsThreeDots
                                  size={22}
                                  className='color-gray-svg'
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    props.handleCommentDelete(
                                      comment.id,
                                      comment.replies[0].id,
                                      "reply"
                                    )
                                  }>
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className='comment_like_'>
                          {comment.replies[0].like_count}
                          <BsHeart
                            onClick={() =>
                              props.addRemoveFavReply(
                                comment.id,
                                comment.replies[0].id,
                                comment.replies[0].liked,
                                comment.replies[0].like_id
                              )
                            }
                            size={18}
                            className='color-gray-svg'
                            style={{
                              fill: comment.replies[0].liked == true && "red",
                            }}
                          />
                        </div>
                      </div>

                      <div className='col-12'>
                        <Dropdown.Menu className='reply d-flex align-items-center mt-2'>
                          <input
                            value={props.reply}
                            onChange={(e) => props.setReply(e.target.value)}
                            type='text'
                            placeholder='Reply...'
                            className='form-control ml-0 rounded'
                          />
                          <button
                            disabled={!props.reply}
                            onClick={() =>
                              props.commentReply(
                                comment.id,
                                "Comment",
                                comment.replies[0].id
                              )
                            }
                            className='btn btn-outline-dark'>
                            Post
                          </button>
                        </Dropdown.Menu>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!viewReplies && comment.replies.length > 1 && (
            <div>
              <a
                className={"view-more"}
                onClick={() => toggleReplies(true)}>
                View more replies <BsChevronDown size={16} />
              </a>
            </div>
          )}
          {viewReplies &&
            comment.replies.length > 0 &&
            comment.replies.map((reply, index) => {
              if (index !== 0) {
                return (
                  <div className='reply-container '>
                    <div className='d-flex'>
                      <Image
                        className='reply-profile_image'
                        src={
                          reply.profile_picture
                            ? reply.profile_picture
                            : defaultProfileIcon
                        }
                        roundedCircle
                      />
                      <div className='comment_details_root'>
                        <span id='username-text'>{reply.user_name}</span>
                        <p className='comment-body'>{reply.body}</p>

                        <div className=''>
                          <Dropdown className='reply-nested-comment w-100'>
                            <div className='reply-date-container'>
                              <div className='d-flex'>
                                <span className='reply-text'>
                                  <TimeAgo datetime={reply.created_at} />
                                </span>
                                <div
                                  className='reply-nested-comment'
                                  style={{ paddingLeft: "16px" }}>
                                  <Dropdown.Toggle
                                    variant='success'
                                    id='dropdown-reply-nested'>
                                    <span className='reply-text'>Reply</span>
                                  </Dropdown.Toggle>
                                </div>
                                {props.currentUser?.id == reply.user_id ? (
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant='success'
                                      id='dropdown-basic'
                                      style={{ marginLeft: "16px" }}>
                                      <BsThreeDots
                                        size={22}
                                        className='color-gray-svg'
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() =>
                                          props.handleCommentDelete(
                                            comment.id,
                                            reply.id,
                                            "reply"
                                          )
                                        }>
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className='comment_like_'>
                                {reply.like_count}
                                <BsHeart
                                  onClick={() =>
                                    props.addRemoveFavReply(
                                      comment.id,
                                      reply.id,
                                      reply.liked,
                                      reply.like_id
                                    )
                                  }
                                  size={18}
                                  className='color-gray-svg'
                                  style={{
                                    fill: reply.liked == true && "red",
                                  }}
                                />
                              </div>
                            </div>

                            <div className='col-12'>
                              <Dropdown.Menu className='reply d-flex align-items-center mt-2'>
                                <input
                                  value={props.reply}
                                  onChange={(e) =>
                                    props.setReply(e.target.value)
                                  }
                                  type='text'
                                  placeholder='Reply...'
                                  className='form-control ml-0 rounded'
                                />
                                <button
                                  disabled={!props.reply}
                                  onClick={() =>
                                    props.commentReply(
                                      comment.id,
                                      "Comment",
                                      reply.id
                                    )
                                  }
                                  className='btn btn-outline-dark'>
                                  Post
                                </button>
                              </Dropdown.Menu>
                            </div>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};
export default memo(CommentComp);
