import createDataContext from './createDataContext';

const messageReducer = (state, action) => {
  switch (action.type) {
    case 'selected_chat_user':
      console.log('selected_chat_user', action);
      return { ...state, selected: action.payload };
    default:
      return state;
      break;
  }
};

const selectedUser = (dispatch) => {
  return (user) => {
    dispatch({ type: 'selected_chat_user', payload: user });
  };
};

export const { Context, Provider } = createDataContext(
  messageReducer,
  { selectedUser },
  {}
);
