import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import React, { useState } from "react";
import LayoutSkeleton from "./LayoutSkeleton";
import "./sidebar.scss";

export default function SidebarV2({
  children,
  currentUser,
  handleAction,
  show,
  open,
  drawerOpen,
  logUserOut,
  ignoreRoutelist,
}) {
  const [isLogout, setIsLogout] = useState(false);

  const logout = () => {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios
      .delete("/users/sign_out", {
        headers: {
          "content-type": "application/json",
          "X-CSRF-Token": token,
        },
      })
      .then(function (response) {
        setIsLogout(true);
        logUserOut();
        window.location.href = "/";
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  return (
    <div className='sidebar_root'>
      <Snackbar
        open={isLogout}
        autoHideDuration={6000}
        onClose={() => setIsLogout(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          onClose={() => setIsLogout(false)}
          severity='info'>
          Please wait, logging out!
        </Alert>
      </Snackbar>
      <div>
        <LayoutSkeleton
          handleAction={handleAction}
          show={show}
          open={open}
          drawerOpen={drawerOpen}
          logoutFnc={logout}
          currentUser={currentUser}
          ignoreRoutelist={ignoreRoutelist}>
          <div className='content_area_root'>{children}</div>
        </LayoutSkeleton>
      </div>
    </div>
  );
}
