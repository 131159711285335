import React, { useState, useEffect, useRef } from "react";
import Swiper from "react-id-swiper";

const VerticalSlider = (props) => {
  const {
    isSmallScreen,
    onScrollVideoIdnex,
    posts,
    children,
    isCommentShow,
    closeShowMoreMeuFnc,
  } = props;

  const swiperRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [height, setHeight] = useState(null);

  const params = {
    direction: "vertical",
    height: isSmallScreen ? height : height - 100, //700
    autoHeight: true,
    on: {
      realIndexChange: (swiper) => {
        return onScrollVideoIdnex(swiper.realIndex);
      },
    },
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  useEffect(() => {
    let heightVideoContainer =
      document.getElementById("video-container").offsetHeight;
    setHeight(heightVideoContainer);
  });

  useEffect(() => {
    const handleWheelEvent = (event) => {
      closeShowMoreMeuFnc();

      if (!isScrolling) {
        if (event.deltaY < 0) {
          goPrev();
        } else if (event.deltaY > 0) {
          goNext();
        }
        setIsScrolling(true);
        setTimeout(() => {
          setIsScrolling(false);
        }, 1000);
      }
    };
    if (!isCommentShow) {
      window.addEventListener("wheel", handleWheelEvent);
    }
    return () => {
      window.removeEventListener("wheel", handleWheelEvent);
    };
  }, [isScrolling, goPrev, goNext]);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "ArrowDown" || e.key === "ArrowRight") {
        goNext();
      } else if (e.key === "ArrowUp" || e.key === "arrowLeft") {
        goPrev();
      }
    });
  }, []);

  return (
    <>
      {/* {isSmallScreen && posts?.length ? ( */}
      {posts?.length ? (
        <>
          <Swiper
            {...params}
            ref={swiperRef}>
            {children}
          </Swiper>
        </>
      ) : (
        children
      )}
    </>
  );
};

export default VerticalSlider;
