import { withStyles } from "@material-ui/styles";
import React from "react";
import { mediaQuery } from "../../hooks/mediaQuery";
import { styleSheet } from "./styles";

const ContentContainer = ({ children, rightBar, classes, style }) => {
  const { isMdUp } = mediaQuery();
  return (
    <div
      className={classes.root}
      style={style ? style : {}}>
      {children} {isMdUp ? rightBar : null}
    </div>
  );
};

export default withStyles(styleSheet, { name: "contentContainerStyles" })(
  ContentContainer
);
