import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";
import Card from "react-bootstrap/Card";
import ReactPlayer from "react-player";
import axios from "axios";
import heartIcon from "../image/heart.png";
import redheartIcon from "../image/redheart.png";
import { RiShareForwardFill } from "react-icons/ri";
import { BsHeartFill } from "react-icons-all-files/bs/BsHeartFill";
import { FaCommentDots } from "react-icons/fa";
import { FaBookmark } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import checkScreenSmall from "./hooks/checkScreenSmall";
import VerticalSlider from "../helper/verticalSlider";
import { BsFillVolumeUpFill } from "react-icons-all-files/bs/BsFillVolumeUpFill";
import { BsFillVolumeMuteFill } from "react-icons-all-files/bs/BsFillVolumeMuteFill";
import { Context as GeneralContext } from "./context/GeneralContext";
import UserCard from "./userCard";
import AvatarImg from "../image/userAv.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import { mediaQuery } from "./hooks/mediaQuery";
import ContentContainer from "./common/ContentContainer";
import dp from "../image/notification/dp.png";
import image from "../image/notification/image.png";
import backgroungImage from "../image/notification/background.png";
import backgroungimg from "../image/1.png";
import Recent1 from "../image/41.png";
import Recent2 from "../image/Recent2.png";
import Recent3 from "../image/Recent3.png";
import Recent4 from "../image/43.png";
import "./profile/posts_index.scss";
// import RightbarV1 from "./common/RightBar/Variant1";
import CommentModalMobile from "./common/CommentModalMobile";
import LoaderComponent from "../helper/loader";
import { MdMoreVert } from "react-icons/md";
import { Menu, MenuItem } from "@material-ui/core";

import "../styles/video_queue.scss";
import "./foryou/foryou.scss";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const config = {
  headers: { "content-type": "multipart/form-data" },
};

const noShadow = {
  boxShadow: "none !important",
};

export default function VideoQueue(props) {
  const { isSmDown } = mediaQuery();
  const [readyVideo, setVieoReady] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  let likesHash = {};
  let videoRatio = {};
  const [likes, setLikes] = useState({});
  const [posts, setPosts] = useState([]);
  const [index, setIndex] = useState(0);
  const [stopVideoIndex, setStopVideoIndex] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [offwidth, setOffwidth] = useState(0);
  const [muteState, setMuteState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isCommentShow, setIsCommentShow] = useState(false);
  const [currentPostId, setCurrentPostId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const showMoreMenuFnc = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeShowMoreMeuFnc = () => {
    setAnchorEl(null);
  };

  const ref = React.createRef();
  const { state, subscribeModalMethods, setLoaderMethod } =
    useContext(GeneralContext);
  let isSmallScreen = checkScreenSmall()[0].smallest ? true : false;

  const onStopVideo = (i) => {
    if (i !== stopVideoIndex) {
      setStopVideoIndex(i);
    } else {
      setStopVideoIndex(null);
    }
  };

  const onSubscribe = (item) => {
    if (props?.currentUser) {
      subscribeModalMethods(true);
      history(`/profiles/${item.profile_id}`);
    } else {
      props.handleAction(true);
    }
  };
  const redirectToProfile = (profile_id) => {
    if (props.currentUser) {
      history(`/profiles/${profile_id}`);
    } else {
      props.handleAction(true);
    }
    // axios.get(`profiles/${profile_id}`)
    // .then((response) => {
    //   console.log(response.data);
    // });
  };

  const redirectToSubscriptionHistory = () => {
    if (props.currentUser) {
      history("/settings/subscription-history");
    } else {
      props.handleAction(true);
    }
  };

  const redirectToPostDetail = (username, post_id) => {
    if (props.currentUser) {
      history(`/posts/${post_id}/comments`);
    } else {
      props.handleAction(true);
    }
  };

  const redirectToShare = () => {
    if (props.currentUser) {
      alert("This module is under development");
    } else props.handleAction(true);
  };

  const addRemoveFav = (id, like_count) => {
    if (props?.currentUser) {
      let formData = new FormData();
      formData.append("like[likeable_id]", id); //append the values with key, value pair
      formData.append("like[value]", likes[`${id}`]["liked"]);
      formData.append("like[likeable_type]", "ForYou");

      if (likes[`${id}`]["liked"] == true) {
        let like_id = likes[`${id}`]["like_id"];
        console.log("LIKE_ID", like_id);
        axios
          .delete(`/likes/${like_id}`, formData, config)
          .then((response) => {
            likes[`${id}`]["liked"] = false;
            likes[`${id}`]["like_id"] = null;
            likes[`${id}`]["like_count"] -= 1;
            setLikes(Object.assign({}, likes));
          })
          .catch((error) => {
            alert(error);
          });
      } else {
        axios
          .post("/likes", formData, config)
          .then((response) => {
            console.log("REPONSE1", response);
            likes[`${id}`]["liked"] = true;
            console.log("RESPONSIBLE", response.data.id);
            likes[`${id}`]["like_id"] = response.data.id;
            likes[`${id}`]["like_count"] += 1;
            setLikes(Object.assign({}, likes));
          })
          .catch((error) => {
            alert(error);
          });
      }
    } else {
      props.handleAction(true);
    }
  };

  const setIcon = (postId, val, id, like_count) => {
    var ele = (
      <div>
        <img
          src={val == true ? redheartIcon : heartIcon}
          style={{ width: 35, outline: "none", textAlign: "left" }}
          onClick={() => addRemoveFav(postId, val, id, like_count)}
        />
        {like_count}
      </div>
    );
    ReactDOM.render(ele, document.getElementById("post-" + postId));
  };

  function handleScroll(e) {
    e.preventDefault();
  }

  const saveBookmark = (post_id, index) => {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    setLoaderMethod(true);
    if (props?.currentUser) {
      axios
        .post("/bookmarks", { for_you_id: post_id }, { headers: { "X-CSRF-Token": token } })
        .then((response) => {
          console.log("RESPONSE", response.data);
          const postData = [...posts];
          postData[index].bookmark = response.data;
          setPosts(postData);
          setLoaderMethod(false);
          toast("bookmark saved successfully!");
        })
        .catch((error) => {
          setLoaderMethod(false);
          toast.error("there is internal error");
        });
    } else {
      props.handleAction(true);
      setLoaderMethod(false);
    }
  };

  const deleteBookmark = (id, post_id, index) => {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios
      .delete(`/bookmarks/${id}`, { headers: { "X-CSRF-Token": token } })
      .then(() => {
        const postData = [...posts];
        postData[index]?.bookmark && delete postData[index].bookmark;
        setPosts(postData);
        setLoaderMethod(false);
        toast("bookmark removed successfully!");
      })
      .catch((error) => {
        setLoaderMethod(false);
        toast.error("there is internal error");
      });
    setLoaderMethod(false);
  };

  useEffect(() => {
    // Update the document title using the browser API
    console.log("aaaaaaaa");
    const getPosts = async (value) => {
      setLoading(true);
      try {
        const searchUrl = "/get_posts";
        const data = await axios.get(searchUrl);
        console.log("aaaaaaa", data);
        data.data.posts.forEach((post) => {
          likesHash[`${post.id}`] = {};
          likesHash[`${post.id}`]["liked"] = post.liked;
          likesHash[`${post.id}`]["like_id"] = post.like_id;
          likesHash[`${post.id}`]["like_count"] = post.like_count;
        });
        setLikes(likesHash);

        setPosts(data.data.posts);
        setLoading(false);
        return;
      } catch (error) {
        setLoading(false);

        return null;
      }
    };
    getPosts();
  }, []);

  useEffect(() => {
    const element = document.getElementsByTagName("video");

    const width = document.getElementById("player_wrapper_")?.offsetWidth;
    setOffwidth(width);
    for (let i = 0; i < element.length; i++) {
      element.disablePictureInPicture = true;
      if (element[i].videoWidth > element[i].videoHeight) {
        videoRatio[i] = false;
      } else {
        videoRatio[i] = true;
      }
    }
    setIndex(0);
    setPlaying(true);
  }, []);

  useEffect(() => {
    if (posts) {
      window.onscroll = () => {
        console.log("window");
        const element = document.getElementsByTagName("video");
        const height = document.getElementById("video0").offsetHeight;
        const width = document.getElementById("player_wrapper_").offsetWidth;
        setOffwidth(width);
        let $index = Math.round(document.scrollingElement.scrollTop / height);
        setIndex($index);
        setPlaying(true);
        setStopVideoIndex(null);
      };
    }
  }, [index]);

  const muteStateSet = () => {
    setMuteState((prev) => !prev);
  };

  const handleScrollVideo = (index) => {
    setIndex(index);
    setPlaying(true);
    setStopVideoIndex(null);
  };

  const recentImg = [Recent1, Recent2, Recent3, Recent4];

  const setIsCommentShowAction = (state) => {
    setIsCommentShow(state);
  };

  const setCurrentPostIdAction = (post) => {
    setCurrentPostId(post?.id);
  };

  return (
    <>
      <ContentContainer>
        <>
          <div
            onScroll={(e) => handleScroll(e)}
            id='video-container'
            style={{ height: "calc(100dvh - 8px)", overflow: "hidden" }}>
            {loading ? <LoaderComponent loaderState /> : null}

            <VerticalSlider
              posts={posts}
              closeShowMoreMeuFnc={closeShowMoreMeuFnc}
              isSmallScreen={isSmDown}
              isCommentShow={isCommentShow}
              onScrollVideoIdnex={(videoIndex, t) => {
                handleScrollVideo(videoIndex);
              }}>
              {posts.map((post, i) => {
                return (
                  <Card
                    onScroll={(e) => handleScroll(e)}
                    id={`video${i}`}
                    key={i}
                    className='cardClass'>
                    <Card.Body>
                      <div className='video-container'>
                        {post.service_url.search(".JPG") >= 0 ? (
                          <img
                            src={post.service_url}
                            alt='post'
                            className='react-player'
                          />
                        ) : (
                          <div id='player_wrapper_'>
                            <ReactPlayerCustom
                              loop={i == index ? true : false}
                              muted={muteState}
                              id='react-player'
                              playing={playing && i == index}
                              config={{
                                file: {
                                  forceVideo: true,
                                  attributes: {
                                    controlsList:
                                      "nodownload noplaybackrate novolume",
                                    disablePictureInPicture: true,
                                    attributes: { preload: "auto" },
                                  },
                                },
                              }}
                              className='react-player'
                              url={post?.service_url}
                              onClick={() => setPlaying((prev) => !prev)}
                            />
                            <div className='user-profile video-player-userinfo__'>
                              <p className='video_status__'>
                                {post?.description?.substring(0, 80) || null}
                                {post?.description.length > 80 ? "..." : ""}
                              </p>
                              <div>
                                <UserCard
                                  postTime='50 mints ago'
                                  imgSrc={
                                    post.profile_picture
                                      ? post.profile_picture
                                      : AvatarImg
                                  }
                                  username={post.user_name}
                                  onClick={() =>
                                    redirectToProfile(post.profile_id)
                                  }
                                  button={
                                    <button
                                      className='subscribe-button-queue_home'
                                      onClick={() => onSubscribe(post)}>
                                      Subscribe
                                    </button>
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <div className='player-volumne-control__'>
                                <div>
                                  {muteState ? (
                                    <BsFillVolumeMuteFill
                                      size={32}
                                      onClick={muteStateSet}
                                    />
                                  ) : (
                                    <BsFillVolumeUpFill
                                      size={32}
                                      onClick={muteStateSet}
                                    />
                                  )}
                                </div>
                              </div>
                              {!(playing && i === index) ? (
                                <div
                                  className='play-pause__icon__'
                                  onClick={() => setPlaying((prev) => !prev)}>
                                  <PlayCircleOutlineIcon
                                    style={{ fontSize: "32px" }}
                                  />
                                </div>
                              ) : (
                                <div
                                  className='play-pause__icon__'
                                  onClick={() => setPlaying((prev) => !prev)}>
                                  <PauseCircleOutlineIcon
                                    style={{ fontSize: "32px" }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <div className='video-container__icons-container'>
                          <div className='video-container--icons-container--icon-container'>
                            <Tooltip
                              title={
                                likes[`${post.id}`]["liked"] == true
                                  ? "Remove Like"
                                  : "Like"
                              }
                              placement='left'>
                              <a href='#'>
                                <BsHeartFill
                                  onClick={() =>
                                    addRemoveFav(post.id, post.like_count)
                                  }
                                  size={24}
                                  className='icon-color'
                                  style={{
                                    color:
                                      likes[`${post.id}`]["liked"] == true &&
                                      "#ed2226",
                                  }}
                                />
                              </a>
                            </Tooltip>
                            <span className='video-container--icon-count-text'>
                              {likes[`${post.id}`]["like_count"]
                                ? `${29.5 + 1}k`
                                : "29.5k"}
                            </span>
                          </div>
                          <div className='video-container--icons-container--icon-container'>
                            <Tooltip
                              title='Comment'
                              placement='left'>
                              <a
                                style={{ color: "unset" }}
                                onClick={() => {
                                  if (isSmDown) {
                                    // Mobile view - show modal
                                    // setIsCommentShowAction(!isCommentShow);
                                    // setCurrentPostIdAction(post);
                                    redirectToPostDetail(post.user_name, post.id);
                                  } else {
                                    // Desktop view - redirect to post detail page
                                    redirectToPostDetail(post.user_name, post.id);
                                  }
                                }}>
                                <FaCommentDots
                                  size={24}
                                  className='icon-color'
                                />
                              </a>
                            </Tooltip>
                            <span className='video-container--icon-count-text'>
                              {post.comment_count || "1.7k"}
                            </span>
                          </div>
                          <div className='video-container--icons-container--icon-container'>
                            <Tooltip
                              title='Share'
                              placement='left'>
                              <a
                                style={{ color: "unset" }}
                                onClick={() => redirectToShare()}>
                                <RiShareForwardFill
                                  size={24}
                                  className='icon-color'
                                />
                              </a>
                            </Tooltip>
                            <span className='video-container--icon-count-text'>
                              20.2k
                            </span>
                          </div>
                          <div className='video-container--icons-container--icon-container'>
                            {post?.bookmark ? (
                              <Tooltip
                                title='Remove from Bookmark'
                                placement='left'>
                                <a style={{ color: "unset" }}>
                                  <FaBookmark
                                    onClick={() =>
                                      deleteBookmark(
                                        post?.bookmark?.id,
                                        post.id,
                                        i
                                      )
                                    }
                                    color={"primary"}
                                    fontSize='large'
                                    className='icon-color'
                                    style={{ color: "#ed2226" }}
                                  />
                                </a>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title='Save Bookmark'
                                placement='left'>
                                <a style={{ color: "unset" }}>
                                  <FaBookmark
                                    onClick={() => saveBookmark(post.id, i)}
                                    fontSize='large'
                                    className='icon-color'
                                  />
                                </a>
                              </Tooltip>
                            )}
                            <span className='video-container--icon-count-text'>
                              300k
                            </span>
                          </div>

                          {/* More Icon */}
                          <div className='video-container--icons-container--icon-container'>
                            <Tooltip
                              title='More '
                              placement='left'>
                              <a
                                style={{ color: "unset" }}
                                aria-controls='foryou-post-more-menu'
                                aria-haspopup='true'
                                onClick={showMoreMenuFnc}>
                                <MdMoreVert
                                  size={24}
                                  className='icon-color'
                                />
                              </a>
                            </Tooltip>
                            <Menu
                              id='foryou-post-more-menu'
                              classes={{ paper: noShadow }}
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={closeShowMoreMeuFnc}>
                              <MenuItem onClick={closeShowMoreMeuFnc}>
                                Profile
                              </MenuItem>
                              <MenuItem onClick={closeShowMoreMeuFnc}>
                                Subscribe
                              </MenuItem>
                              <MenuItem onClick={closeShowMoreMeuFnc}>
                                Report
                              </MenuItem>
                            </Menu>
                          </div>

                          {/* Profile Icon */}
                          <div className='video-container--icons-container--icon-container'>
                            <Tooltip
                              title='Visit Profile'
                              placement='left'>
                              <a
                                style={{
                                  color: "unset",
                                  backgroundImage: `url(${AvatarImg})`,
                                  backgroundRepeat: "no-repeat",
                                  border: "1px solid #dedede",
                                  backgroundSize: "cover",
                                }}
                                onClick={() => {
                                  // redirectToPostDetail(post.user_name, post.id)
                                }}>
                                {/* <MdMoreVert
                 size={24}
                 className='icon-color'
                /> */}
                              </a>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
              {/* </div> */}
            </VerticalSlider>

            <CommentModalMobile
              currentPostId={currentPostId}
              commentType='ForYou'
              currentVideo={index}
              isCommentShow={isCommentShow}
              setIsCommentShowAction={setIsCommentShowAction}
            />
          </div>
        </>
      </ContentContainer>
    </>
  );
}

const ReactPlayerCustom = ({ loop, muted, playing, url, onClick }) => {
  const [readyVideo, setVideoReady] = useState(false);
  return (
    <>
      <ReactPlayer
        onReady={() => setVideoReady(true)}
        loop={loop}
        muted={muted}
        id='react-player'
        playing={playing}
        config={{
          file: {
            forceVideo: true,
            attributes: {
              controlsList: "nodownload noplaybackrate novolume",
              disablePictureInPicture: true,
              attributes: { preload: "auto" },
            },
          },
        }}
        className='react-player'
        url={url}
        onClick={onClick}
      />
      {!readyVideo ? (
        <div className='video-loader-style'>
          <CircularProgress
            color='secondary'
            size={84}
          />
        </div>
      ) : null}
    </>
  );
};

const suggestionData = [
  {
    list: [
      {
        name: "Rye",
        profileImage: dp,
        free: "Free",
        bgImage: backgroungImage,
      },
      {
        name: "Whole",
        profileName: "@kataleyahot",
        profileImage: image,
        free: "Free",
        bgImage: backgroungimg,
      },

      {
        name: "Soffa",
        profileName: "@ry3zizzle",
        date: "Feb 5",
        profileImage: dp,
        free: "Free",
        bgImage: backgroungImage,
      },
    ],
  },
  {
    list: [
      {
        name: "Rye",
        profileImage: dp,
        free: "Free",
        bgImage: backgroungImage,
      },
      {
        name: "Whole",
        profileName: "@kataleyahot",
        profileImage: image,
        free: "Free",
        bgImage: backgroungimg,
      },

      {
        name: "Soffa",
        profileName: "@ry3zizzle",
        date: "Feb 5",
        profileImage: dp,
        free: "Free",
        bgImage: backgroungImage,
      },
    ],
  },
  {
    list: [
      {
        name: "Rye",
        profileImage: dp,
        free: "Free",
        bgImage: backgroungImage,
      },
      {
        name: "Whole",
        profileName: "@kataleyahot",
        profileImage: image,
        free: "Free",
        bgImage: backgroungimg,
      },

      {
        name: "Soffa",
        profileName: "@ry3zizzle",
        date: "Feb 5",
        profileImage: dp,
        free: "Free",
        bgImage: backgroungImage,
      },
    ],
  },
  {
    list: [
      {
        name: "Rye",
        profileImage: dp,
        free: "Free",
        bgImage: backgroungImage,
      },
      {
        name: "Whole",
        profileName: "@kataleyahot",
        profileImage: image,
        free: "Free",
        bgImage: backgroungimg,
      },

      {
        name: "Soffa",
        profileName: "@ry3zizzle",
        date: "Feb 5",
        profileImage: dp,
        free: "Free",
        bgImage: backgroungImage,
      },
    ],
  },
];
