export const styleSheet = () => ({
 root: {
  display: "flex",
  justifyContent: "space-between",
  gap: "6px",
  width: "100%",
  alignItems: "flex-start",
 },
 userData: {
  display: "flex",
  alignItems: "center",
  gap: "6px",
  marginBottom: "8px",
 },
 avatarImg: {
  height: "40px",
  width: "40px",
  borderRadius: "50px",
  cursor: "pointer",
  backgroundColor: "#e1e1e1",
 },
 userText: {
  cursor: "pointer",
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "19px",
  color: "black",
  position: "relative",
  top: "2px",
  marginBottom: 0,
  textTransform: "capitalize",
 },

 postDesc: {
  padding: " 0px 6px",
  color: "#333",
  marginBottom: " 8px",
  fontSize: "16px",
  letterSpacing: "0.2px",
  "& p": {
   marginBottom: 0,
  },
 },
 btnRight: {
  "& button": {
   borderRadius: "4px",
   padding: "0px 13px",
   height: "37px",
  },
  "& svg": {
   height: "14px",
   position: "relative",
   top: "-2px",
   marginRight: "4px",
  },
 },
 postTime: {
  color: "#9b9b9b",
  fontSize: "10px",
  marginBottom: 0,
 },
});
