/*
 * Global theme declaration
 */

import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  // Colors
  palette: {
    // type: "light",
    primary: {
      main: "#1975d2",
    },
    // secondary: {
    //   main: green[500],
    // },
  },
});
