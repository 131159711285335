import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { useLocation } from "react-router-dom";
import MiniDrawer from "./NewDrawer";
import { mediaQuery } from "../hooks/mediaQuery";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // overflow: "hidden",
  },
  paperAnchorLeft: {
    left: "auto",
    right: "auto",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: "-4px",
    top: "8px",
  },
  hide: {
    display: "none",
  },
  paper: {
    overflowX: "hidden",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    minHeight: "63px !important",
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      // padding: theme.spacing(3),
      // paddingTop: "64px !important",
    },
  },
}));

export default function LayoutSkeleton({
  children,
  handleAction,
  open,
  drawerOpen,
  currentUser,
  logoutFnc,
  ignoreRoutelist,
}) {
  const { isSmUp } = mediaQuery();
  const classes = useStyles();
  const location = useLocation();

  const ignoreRoutelistFnc = (ignoreRoute) => {
    let path = [];
    if (ignoreRoute?.length) {
      for (let i = 0; i <= ignoreRoute.length; i++) {
        path.push(ignoreRoute[i]);
      }
    }

    return path?.includes(location?.pathname) ? false : true;
  };

  return (
    <div className={classes.root}>
      {isSmUp && ignoreRoutelistFnc(ignoreRoutelist) ? (
        <MiniDrawer
          drawerOpen={drawerOpen}
          open={open}
          logoutFnc={logoutFnc}
          handleAction={handleAction}
          currentUser={currentUser}
        />
      ) : null}
      <main className={classes.content}>{children}</main>
    </div>
  );
}
