import createDataContext from './createDataContext';

const generalReducer = (state, action) => {
  switch (action.type) {
    case 'subscribe_modal':
      console.log('subscribe_modal', action);
      return { ...state, isShowSubscribeModal: action.payload };
      case 'loader':
        console.log('loader', action);
        return { ...state, isLoaderShow: action.payload };
    default:
      return state;
      break;
  }
};


const subscribeModalMethods = (dispatch) => {
  return (isShow) => {
    dispatch({ type: 'subscribe_modal', payload: isShow });
  };
};

const setLoaderMethod = (dispatch) => {
  return (isShow) => {
    dispatch({ type: 'loader', payload: isShow });
  };
};

export const { Context, Provider } = createDataContext(
    generalReducer,
  { subscribeModalMethods, setLoaderMethod},
  {  isShowSubscribeModal: false, isLoaderShow: false }
);
