export const styleSheet = (theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "8px",
        [theme.breakpoints.down("sm")]: {
            position: "absolute",
            right: "0px",
            left: "0px",
        },
        [theme.breakpoints.up("sm")]: {
            "& >div:nth-child(1)": {
                maxWidth: "599px",
                // minWidth: "563px",
                marginTop: "20px",
            },
        },
        [theme.breakpoints.up("lg")]: {
            "& >div:nth-child(1)": {
                // maxWidth: "630px",
                // minWidth: "630px",
            },
        },
        [theme.breakpoints.up("xl")]: {
            "& >div:nth-child(1)": {
                // maxWidth: "900px",
                maxWidth: "599px",
                // minWidth: "900px",
            },
        },
        "& >div:nth-child(1)": {
            width: "100%",
            margin: "auto",
            marginTop: "0",
        },
        "& >div:nth-child(2)": {
            maxWidth: "368px",
            minWidth: "282px",
            width: "100%",
            position: "relative",
            right: "0px",
            top: "0px",
        },
    },
});
