import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import clsx from "clsx";
import { Drawer, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import CommentComp from "../CommentComp";
import { InfoOutlined } from "@material-ui/icons";
import LoaderComponent from "../../../../helper/loader";
import "./style.scss";
import Comment from "../../../comment/comment";

const config = {
  headers: { "content-type": "multipart/form-data" },
};
const useStyles = makeStyles({
  fullList: {
    width: "auto",
  },
  root: {
    width: 500,
  },
  paperAnchorBottom: {
    padding: "30px 0 0",
    "border-radius": "12px 12px 0",
  },
});
const CommenArea = (props) => {
  const { currentPostId, commentType } = props;
  console.log("COMMENTTYPE", commentType);
  // console.log("post========", comments);

  // console.log("post========", comments["comments"])
  const history = useNavigate();
  const [like_count, setLikesCount] = useState(0);
  const [currentPostIdState, setCurrentPostIdState] = useState("");
  // const params = useParams();
  const params = {
    id: props?.currentPostId,
  };

  const [comments, setComments] = useState([]);
  const [post, setPost] = useState({});
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState("");
  const [comment_count, setCommentCount] = useState(0);
  const [reply, setReply] = useState("");
  const [open, setOpen] = React.useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const [loader, setLoader] = useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styleClasses = useStyles();
  const [state, setState] = React.useState({
    bottom: false,
  });

  const onSubscribe = (item) => {
    history(`/profiles/${item.profile_id}`);
  };

  React.useMemo(() => {
    console.log("aaaaaa", currentPostIdState);
    setComments(null);
    setPost(null);
    setCurrentUser(null);
    setCommentCount(null);

    setCurrentPostIdState(currentPostIdState);
  }, [currentPostIdState]);

  const getDetailData = async (value, commentType) => {
    console.log("valuefdfdfdsfdsfbdsiufbdsiufhsdiufhdsiufhdsiufhsdiuhf", value);
    try {
      let searchUrl;
      if (commentType === 'Post') {
        searchUrl = `/posts/${value}/comments`;
      } else if (commentType === 'ForYou') {
        searchUrl = `/for_yous/${value}/comments`;
      } else {
        throw new Error(`Invalid comment type${commentType}`);
      }
  
      const response = await axios.get(searchUrl);
      console.log("response.data", response.data);
      setComments(response.data.comments);
      setPost(response.data.commentable);
      console.log("post========", response.data.commentable);
      setCurrentUser(response.data.currentUser);
      setCommentCount(response.data.commentable.comment_count);
      setLoader(false);
      return;
    } catch (error) {
      console.error('Error fetching data:', error);
      setComments(null);
      setCommentable(null);
      setCurrentUser(null);
      setCommentCount(null);
      setLoader(false);
      return null;
    }
  };
  useEffect(() => {
    // setCurrentPostIdState(currentPostId);
    setLoader(true);
    getDetailData(currentPostId, commentType);
  }, [currentPostId]);

  const addRemoveFav = (id, type, liked, like_id) => {
    console.log(id, type, liked, like_id);
    let formData = new FormData();
    formData.append("like[likeable_id]", id);
    formData.append("like[value]", liked);
    formData.append("like[likeable_type]", type);
    if (liked == true) {
      axios
        .delete(`/likes/${like_id}`, formData, config)
        .then((response) => {
          post["liked"] = false;
          post["liked_id"] = null;
          post["like_count"] -= 1;
          setPost(Object.assign({}, post));
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      axios
        .post("/likes", formData, config)
        .then((response) => {
          post["liked"] = true;
          post["liked_id"] = response.data.id;
          post["like_count"] += 1;
          setPost(Object.assign({}, post));
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const addRemoveFavComment = (id, type, liked, like_id) => {
    console.log(id, type, liked, like_id);
    let comment_index = comments.findIndex((v) => v.id === id);
    console.log(comments[comment_index]["liked"]);
    let formData = new FormData();
    formData.append("like[likeable_id]", id);
    formData.append("like[value]", liked);
    formData.append("like[likeable_type]", type);
    if (liked == true) {
      axios
        .delete(`/likes/${like_id}`, formData, config)
        .then((response) => {
          setComments((comments) => {
            const newState = comments.map((obj) => {
              if (obj.id === id) {
                return {
                  ...obj,
                  liked: false,
                  like_id: null,
                  like_count: obj.like_count - 1,
                };
              }
              return obj;
            });
            return newState;
          });
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      axios
        .post("/likes", formData, config)
        .then((response) => {
          setComments((comments) => {
            const newState = comments.map((obj) => {
              if (obj.id === id) {
                return {
                  ...obj,
                  liked: true,
                  like_id: response.data.id,
                  like_count: obj.like_count + 1,
                };
              }
              return obj;
            });
            return newState;
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const handleComment = () => {
    let formData = new FormData();
    formData.append("comment[commentable_id]", currentPostId);
    formData.append("comment[body]", comment);
    formData.append("comment[commentable_type]", commentType);
    
    if (commentType === 'Post') {
      formData.append("post_id", post.id);
    } else if (commentType === 'ForYou') {
      formData.append("for_you_id", post.id);
    }
  
    axios
      .post("/comments", formData, config)
      .then((response) => {
        setCommentCount(comment_count + 1);
        setComments(prevComments => [response.data, ...prevComments]);
        setComment("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReply = (id, type, parent_id) => {
    console.log(id, type, "rrrrrrrrrrrrrrrrrrrrrr");
    let formData = new FormData();
    formData.append("comment[commentable_id]", id); //append the values with key, value pair
    formData.append("comment[body]", reply);
    formData.append("comment[commentable_type]", "Comment");
    formData.append("comment[parent_id]", parent_id);

    if (commentType === 'Post') {
      formData.append("post_id", post.id);
    } else if (commentType === 'ForYou') {
      formData.append("for_you_id", post.id);
    }
    axios
      .post("/comments", formData, config)
      .then((response) => {
        setComments((comments) => {
          const newState = comments.map((obj) => {
            if (obj.id === id) {
              obj.replies.unshift(response.data);
            }
            return obj;
          });
          return newState;
        });
        setComments(comments);
        setReply("");
      })
      .catch((error) => {
        alert(error);
      });
    // 'comment' have your taxarea's value you can callapi from here
  };

  const handleCommentDelete = (comment_id, reply_id, type) => {
    if (type == "comment") {
      axios
        .delete(`/comments/${comment_id}`, config)
        .then((response) => {
          setComments((comments) => {
            const newState = comments.filter((data) => data.id != comment_id);
            return newState;
          });
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      axios
        .delete(`/comments/${reply_id}`, config)
        .then((response) => {
          setComments((comments) => {
            const newState = comments.map((obj) => {
              if (obj.id === comment_id) {
                obj.replies = obj.replies.filter((data) => data.id != reply_id);
                return obj;
              }
              return obj;
            });
            return newState;
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const countComments = (value) => {
    let total = 0;
    for (var i = 0; i < value?.length; i++) {
      total = total + 1;
      console.log("0111111", value[i]);
      if (value[i]?.replies?.length) {
        for (let j = 0; j < value[i]?.replies?.length; j++) {
          console.log("01111112", value[i]?.replies[i]);

          total = total + 1;
        }
      }
    }
    return total;
  };

  const addRemoveReplyFav = (comment_id, reply_id, liked, like_id) => {
    console.log(comment_id, reply_id, liked, like_id);
    let formData = new FormData();
    formData.append("like[likeable_id]", reply_id);
    formData.append("like[value]", liked);
    formData.append("like[likeable_type]", "Comment");
    if (liked == true) {
      axios
        .delete(`/likes/${like_id}`, formData, config)
        .then((response) => {
          setComments((comments) => {
            const newState = comments.map((obj) => {
              if (obj.id === comment_id) {
                obj.replies.map((reply) => {
                  if (reply.id === reply_id) {
                    reply.liked = false;
                    reply.like_id = null;
                    reply.like_count -= 1;
                    return reply;
                  }
                  return reply;
                });
              }
              return obj;
            });
            return newState;
          });
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      axios
        .post("/likes", formData, config)
        .then((response) => {
          setComments((comments) => {
            const newState = comments.map((obj) => {
              if (obj.id === comment_id) {
                obj.replies.map((reply) => {
                  if (reply.id === reply_id) {
                    reply.liked = true;
                    reply.like_id = response.data.id;
                    reply.like_count += 1;
                    return reply;
                  }
                  return reply;
                });
              }
              return obj;
            });
            return newState;
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, bottom: open });
  };
  const listDrawer = (anchor) => (
    <div
      className={clsx(styleClasses.listDrawer)}
      role='presentation'
      onfocusout={toggleDrawer(false)}
      style={{ top: "10%" }}>
      <List className='drawer-container'>
        <div className={"comment_video_container"}>
          {/* <div className='comment_video'>
            {post &&
              comments.map((comment) => (
                <CommentComp
                  comment={comment}
                  addRemoveFavReply={addRemoveReplyFav}
                  commentReply={handleReply}
                  reply={reply}
                  setReply={setReply}
                  commentLike={addRemoveFavComment}
                  handleCommentDelete={handleCommentDelete}
                  currentUser={currentUser}
                  props
                />
              ))}
          </div> */}

          <div
            className='new-comment-container'
            style={{ width: "100%" }}>
            <div className='text_input'>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder='Type here...'
                multiline={true}
                className='rce-input rce-input-textarea w-100 px-3'
              />
            </div>
            <div>
              <button
                disabled={!comment}
                onClick={handleComment}
                className='pb-2'>
                Post{" "}
              </button>
            </div>
          </div>
        </div>
      </List>
    </div>
  );
   console.log("POSTPPOSTPSOPOSTPSOTOSTO", post)
  if (post) {
    return !loader ? (
      <div className='comment_video_container comment_container'>
        <Drawer
          classes={{
            paperAnchorBottom: clsx(styleClasses.paperAnchorBottom),
          }}
          className={"drawer-container"}
          anchor={"bottom"}
          open={state["bottom"]}>
          <div
            className={"close-comment"}
            style={{
              position: "absolute",
              fontSize: "19px",
              top: "4px",
              right: "16px",
              cursor: "pointer",
              color: "white",
            }}>
            <span onClick={toggleDrawer(false)}>x</span>
          </div>
          {listDrawer("bottom")}
        </Drawer>
        <Container >
          {/* <AiFillCloseCircle
            onClick={() => history(-1)}
            size={"3em"}
            className='ai-fill-close-circle'
          /> */}
          <Row>
            <div className={"comment-section"} style={{width:"100%"}}>
              <Snackbar
                open={open}
                autoHideDuration={800}
                onClose={handleClose}
                message='Copied'
                style={{ top: "30px", height: "48px" }}
              />
              <div className='comment_video'>
                {countComments(comments) > 0 ? (
                  <p className='_comments_count'>
                    {countComments(comments)} Comments
                  </p>
                ) : null}
                {comments?.length > 0 ? (
                  comments.map((comment) => (
                    <Comment
                      comment={comment}
                      addRemoveFavReply={addRemoveReplyFav}
                      commentReply={handleReply}
                      reply={reply}
                      setReply={setReply}
                      commentLike={addRemoveFavComment}
                      handleCommentDelete={handleCommentDelete}
                      currentUser={currentUser}
                      props
                    />

                  //   <CommentComp
                  //   comment={comment}
                  //   addRemoveFavReply={addRemoveReplyFav}
                  //   commentReply={handleReply}
                  //   reply={reply}
                  //   setReply={setReply}
                  //   commentLike={addRemoveFavComment}
                  //   handleCommentDelete={handleCommentDelete}
                  //   currentUser={currentUser}
                  //   props
                  // />
                  ))
                ) : (
                  <div className='_noComments__'>
                    <InfoOutlined />
                    <h4>No any comments yet!</h4>
                    {/* <p>Be the first in the list</p> */}
                  </div>
                )}
              </div>
              <div className='new-comment_root'>
                <textarea
                  name='comment'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder='Type Comment'
                  multiline={true}
                  className='rce-input rce-input-textarea w-100 px-3'
                />
                <button
                  disabled={!comment}
                  onClick={handleComment}>
                  Post
                </button>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    ) : (
      <LoaderComponent loaderState />
    );
  } else {
    return <div></div>;
  }
};
export default CommenArea;
