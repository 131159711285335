// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

import "../styles/application.scss";
import React from 'react';
import ReactDOM from 'react-dom';
import App from '../components/app.jsx'; // Update this path to wherever your App component lives

document.addEventListener('DOMContentLoaded', () => {
  const mountNode = document.getElementById('root');
  const rawProps = mountNode.getAttribute('data-props');
  const props = JSON.parse(rawProps);

  ReactDOM.render(<App {...props} />, mountNode);
});
