import { deepOrange, deepPurple } from "@material-ui/core/colors";
import "bootstrap/dist/css/bootstrap.css";
import React, {
  useEffect,
  useState,
  createContext,
  Suspense,
  lazy,
} from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useHistory,
  Navigate,
} from "react-router-dom";

import "../styles/app.scss";
const Account = lazy(() => import("./account.jsx"));
const Banking = lazy(() => import("../components/banking/index"));
import { Provider } from "./context/MessageContext";
const BankingRoot = lazy(() => import("../components/banking/New/index"));

import { Provider as GeneralProvider } from "./context/GeneralContext";
import Home from "./home.jsx";
const Messages = lazy(() => import("./message/messages.jsx"));
const NewPostForm = lazy(() => import("./new_post_form"));
const Payments = lazy(() => import("./payment/payments/index"));
const AdvancedSearch = lazy(() => import("./Search/search"));
const BankingInfo = lazy(() =>
  import("../components/payment/bankingInfo/index")
);
const LiveVideo = lazy(() => import("./live_video"));
const LiveStream = lazy(() => import("./LiveStream/test"));
const SingleLiveVideo = lazy(() =>
  import("./LiveStream/SingleLiveVideo/live_video")
);
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
const Statements = lazy(() => import("./statements/index"));
const ViewReferedUser = lazy(() =>
  import("../components/statements/components/viewReferedUser")
);
const Bookmarks = lazy(() => import("./bookmarks/index"));
const Profile = lazy(() => import("./profile/posts_index.jsx"));
const TalentSignIn = lazy(() => import("./talent_sign_in"));
const PostDetail = lazy(() => import("./VideoView/post_detail.jsx"));
import VideoQueue from "./video_queue.jsx";
const MyBookmark = lazy(() => import("./Lists/bookmarks/index"));
const AddUserBookmark = lazy(() => import("./Lists/bookmarks/addUserBookmark"));
const CloseFriends = lazy(() => import("./Lists/closeFriends/index"));
const Friends = lazy(() => import("./Lists/friends/index"));
const Following = lazy(() => import("./Lists/following/index"));
const Fans = lazy(() => import("./Lists/fans/index"));
const Restricted = lazy(() => import("./Lists/restricted/index"));
const Blocked = lazy(() => import("./Lists/blocked/index"));
const ViewDetails = lazy(() => import("./Lists/viewDetails/index"));
const AddUserList = lazy(() => import("./Lists/addUserList/index"));
const AddUser = lazy(() => import("./Lists/addUserList/addUser"));
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Lists = lazy(() => import("../components/Lists/index"));
const Contact = lazy(() => import("./contact"));
const Term = lazy(() => import("./term"));
const Privacy = lazy(() => import("./privacy"));
const CookieNotice = lazy(() => import("./cookieNotice"));
import LoaderComponent from "../helper/loader";
const AddCloseFriendUser = lazy(() => import("./Lists/closeFriends/addUser"));
const AddUserFriends = lazy(() => import("./Lists/friends/addUserFriends"));
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
const Vault = lazy(() => import("./vault/index"));
const ViewCategory = lazy(() =>
  import("./vault/components/viewCategoryFilter")
);
const Category = lazy(() => import("./vault/category"));
const Post = lazy(() => import("./vault/post"));
const NewPost = lazy(() => import("./post/create/index"));
const StoryPreviewComp = lazy(() =>
  import("./MyStory/components/StoryPreviewComp")
);
const EditPost = lazy(() => import("./post/edit"));
const Queue = lazy(() => import("./queue"));
const PostLabels = lazy(() => import("./PostLabels/index"));
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
const QueueCalendar = lazy(() => import("./post_queue/messages_queue"));
const SaveForLatter = lazy(() => import("./queue/saveForLatter/index"));
import { createBrowserHistory } from "history";
const BankingBillingSupport = lazy(() => import("./banking/billingSupport"));
const StatementBillingSupport = lazy(() =>
  import("./statements/billingSupport")
);
import { theme } from "../helper/theme";
const Settings = lazy(() => import("./settings/settings"));
const SettingsIndex = lazy(() => import("./settings"));
import SidebarV2 from "./sidebar/sidebarV2";
const FeedPage = lazy(() => import("./feed/feed"));
const Subscription = lazy(() => import("./subscription/index"));
const Notification = lazy(() => import("./notification/index"));
const Statistics = lazy(() => import("./statistics/index"));
const Search = lazy(() => import("./Search/search"));
// import actionCable from "actioncable"
const Contract = lazy(() => import("./contract"));
const ShowPost = lazy(() => import("./post/show"));
const Collection = lazy(() => import("./collection/index"));
const MassMessagesStatistics = lazy(() =>
  import("./message/mass_messages_statistics")
);
const EditBankDetails = lazy(() => import("./banking/New/EditBankDetails.jsx"));

// const cableApp = {}
// cableApp.cable = actionCable.createConsumer(`wss://${process.env.MYHOST}/cable`);

const USC = lazy(() => import("./2257"));

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

export const GlobalContext = createContext();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    height: 45,
  },
}));

const App = (props) => {
  const history = createBrowserHistory();
  console.log("history: ", history);
  //
  // console.log("SADASDASDASDASDASDASDASDASDASD",props);
  // if(props.path == ""){
  //    history.push("/messages")
  // }

  const redirectToSettings = () => {
    history.push(`/settings/profile`);
  };

  const redirectToHome = () => {
    history.push(`/`);
  };

  const redirectToPostsNew = () => {
    history.push(`/posts/new`);
  };

  const redirectToMessages = () => {
    history.push(`/messages`);
  };

  useEffect(() => {
    setCurrentPath("");
  }, [history.location.pathname]);

  const [currentPath, setCurrentPath] = useState(props.path);

  const [message, setMessage] = useState(props.message);

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(true);
  const [sessionType, setSessionType] = useState("Login");
  const [sessionState, setSessionState] = useState("");
  const pathName = window.location.pathname;
  const [params, setParams] = useState({});

  const current_time = new Date().toLocaleTimeString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });

  const [selectedDate, setSelectedDate] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [isScheduled, setIsScheduled] = useState(false);
  // Mass Messages States
  const [toggleMassMessages, setToggleMassMessages] = useState(false);
  // List Id State
  const [listId, setListId] = useState("");
  // All Lists States
  const [allLists, setAllLists] = useState("");
  const [isSelectMessages, setIsSelectMessages] = useState(false);
  const handleAction = (action) => setShow(action || !show);
  // select multiple users states
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isMobileContent, setIsMobileContent] = useState(false);
  const [includedLists, setIncludedLists] = useState([]);
  const [excludedLists, setExcludedLists] = useState([]);
  const [saveLaterOption, setSaveLaterOption] = useState("");
  const [isSaveLater, setIsSaveLater] = useState(false);
  const [isScheduleMessage, setIsScheduleMessage] = useState(false);

  function logUserOut() {
    setSessionState("logout");
  }

  function changeSessionState() {
    console.log("CHANGE SESSION");
    if (sessionType === "Login") {
      setSessionType("Sign Up");
    } else {
      setSessionType("Login");
    }
  }
  const [walletMessage, setWalletMessage] = React.useState(
    props.wallet_message
  );
  const [value, setValue] = React.useState("recents");
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const drawerOpen = () => {
    setOpen(!open);
  };

  const globalContextValues = {
    isScheduled: isScheduled,
    setIsScheduled: setIsScheduled,
    selectedDate: selectedDate,
    setSelectedDate: setSelectedDate,
    scheduleTime: scheduleTime,
    setScheduleTime: setScheduleTime,
    toggleMassMessages: toggleMassMessages,
    setToggleMassMessages: setToggleMassMessages,
    listId: listId,
    setListId: setListId,
    allLists: allLists,
    setAllLists: setAllLists,
    isSelectMessages: isSelectMessages,
    setIsSelectMessages: setIsSelectMessages,
    selectedUsers: selectedUsers,
    setSelectedUsers: setSelectedUsers,
    isMobileContent: isMobileContent,
    setIsMobileContent: setIsMobileContent,
    includedLists: includedLists,
    setIncludedLists: setIncludedLists,
    excludedLists: excludedLists,
    setExcludedLists: setExcludedLists,
    setSaveLaterOption: setSaveLaterOption,
    saveLaterOption: saveLaterOption,
    isSaveLater: isSaveLater,
    setIsSaveLater: setIsSaveLater,
    isScheduleMessage: isScheduleMessage,
    setIsScheduleMessage: setIsScheduleMessage,
  };

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <div id='_app-root'>
        <Provider>
          <BrowserRouter history={history}>
            <GeneralProvider>
              <GlobalContext.Provider value={globalContextValues}>
                {history?.location?.pathname === "/stream" ? (
                  <Suspense
                    fallback={
                      <div>
                        <LoaderComponent loaderState />
                      </div>
                    }>
                    <Routes>
                      <Route
                        exact
                        path='/stream'
                        element={<LiveStream />}
                      />
                    </Routes>
                  </Suspense>
                ) : (
                  <SidebarV2
                    isFixed
                    currentUser={props?.currentUser}
                    handleAction={handleAction}
                    show={show}
                    drawerOpen={drawerOpen}
                    open={open}
                    logUserOut={logUserOut}
                    ignoreRoutelist={ignoreListRoutes} // Example: {["/messages", "profile"]} | Type: Array[String]
                  >
                    <LoaderComponent />
                    <Home
                      show={show}
                      props={props}
                      handleAction={handleAction}
                      sideDrawerState={open}
                      logoutFnc={logUserOut}
                    />
                    <Suspense
                      fallback={
                        <div>
                          <LoaderComponent loaderState />
                        </div>
                      }>
                      <Routes>
                        <Route
                          exact
                          path='/stream'
                          element={<LiveStream />}
                        />
                        <Route
                          exact
                          path='/'
                          element={
                            currentPath.length > 0 ? (
                              <Navigate to={currentPath} />
                            ) : (
                              <VideoQueue
                                handleAction={handleAction}
                                show={show}
                                currentUser={props?.currentUser}
                              />
                            )
                          }
                        />
                        <Route
                          exact
                          path='/feed'
                          element={
                            props?.currentUser ? (
                              <FeedPage
                                currentUser={props?.currentUser}
                                handleAction={handleAction}
                              />
                            ) : (
                              <Navigate to={"/"} />
                            )
                          }
                        />
                        <Route
                          exact
                          path='/contact'
                          element={<Contact />}
                        />
                        <Route
                          exact
                          path='/terms'
                          element={<Term />}
                        />
                        <Route
                          exact
                          path='/privacy'
                          element={<Privacy />}
                        />
                        <Route
                          exact
                          path='/cookie-notice'
                          element={<CookieNotice />}
                        />

                        <Route
                          exact
                          path='/2257'
                          element={<USC />}
                        />

                        <Route
                          exact
                          path='/contract'
                          element={<Contract />}
                        />

                        {props.currentUser ? (
                          <>
                            <Route
                              path='/messages/*'
                              element={<Messages user={props.currentUser} />}
                            />
                            <Route
                              path='/stories/:categoryName'
                              element={<StoryPreviewComp />}
                            />
                            {/* <Route path="/accounts" element={<Account />} /> */}
                            <Route
                              path='/settings/*'
                              element={<SettingsIndex />}
                            />
                            {/* <Route
                      path="/settings/profile"
                      element={<Account />}
                    /> */}
                            {/* <Route path="/settings/*" element={<Settings />} /> */}
                            <Route
                              exact
                              path='/post'
                              element={<NewPost />}
                            />

                            <Route
                              exact
                              path='/posts/:id'
                              // element={<PostDetail />}
                              element={
                                <ShowPost
                                  currentUser={props.currentUser}
                                  handleAction={handleAction}
                                />
                              }
                            />
                            <Route
                              exact
                              path='/posts/edit'
                              element={<EditPost />}
                            />
                            <Route
                              exact
                              path='/posts/:id/comments'
                              element={<PostDetail />}
                            />
                            {/*<Route*/}
                            {/*  exact*/}
                            {/*  path="/posts/:videoId"*/}
                            {/*  element={<PostDetail />}*/}
                            {/*/>*/}
                            <Route
                              exact
                              path='/profiles/:profileId'
                              element={
                                <Profile
                                  message={message}
                                  setMessage={setMessage}
                                  currentUser={props.currentUser}
                                />
                              }
                            />
                            <Route
                              exact
                              path='/profile'
                              element={
                                <Profile
                                  currentUser={props.currentUser}
                                  loginedUser
                                />
                              }
                            />
                            <Route
                              exact
                              path='/banking/billingsupport'
                              element={<BankingBillingSupport />}
                            />
                            <Route
                              exact
                              path='/banking/edit'
                              element={<EditBankDetails />}
                            />
                            <Route
                              exact
                              path='/banking'
                              element={
                                <Banking currentUser={props.currentUser} />
                              }
                            />

                            <Route
                              exact
                              path='/banking_info'
                              element={() => <BankingInfo />}
                            />
                            <Route
                              exact
                              path='/statements/billingsupport'
                              element={<StatementBillingSupport />}
                            />
                            <Route
                              exact
                              path='/statements'
                              element={<Statements />}
                            />
                            <Route
                              exact
                              path='/viewReferedUser'
                              element={<ViewReferedUser />}
                            />
                            {/* <Route
                        exact
                        path="/statements/viewReferedUser/billing"
                        element={<BillingSupport />}
                      /> */}
                            <Route
                              exact
                              path='/bookmarks/:slug'
                              element={<Bookmarks />}
                            />
                            <Route
                              exact
                              path='/payments'
                              element={
                                <Payments
                                  setWalletMessage={setWalletMessage}
                                  message={walletMessage}
                                />
                              }
                            />
                            <Route
                              exact
                              path='/search/'
                              element={
                                <Search
                                  currentUser={props?.currentUser}
                                  handleAction={handleAction}
                                />
                              }
                            />
                            <Route
                              exact
                              path='/advanced_search'
                              element={<AdvancedSearch />}
                            />
                            <Route
                              exact
                              path='/live/:id'
                              element={
                                <SingleLiveVideo
                                  currentUser={props?.currentUser}
                                  handleAction={handleAction}
                                />
                              }
                            />
                            <Route
                              exact
                              path='/live'
                              element={
                                <LiveVideo
                                  currentUser={props?.currentUser}
                                  handleAction={handleAction}
                                />
                              }
                            />
                            <Route
                              exact
                              path='/lists'
                              element={<Lists />}
                            />
                            <Route
                              exact
                              path='/my/bookmarks'
                              element={<MyBookmark />}
                            />
                            <Route
                              exact
                              path='/my/bookmarks/add'
                              element={<AddUserBookmark />}
                            />
                            <Route
                              exact
                              path='/my/close_friends'
                              element={<CloseFriends />}
                            />
                            <Route
                              exact
                              path='/my/close_friends/add_user'
                              element={<AddCloseFriendUser />}
                            />
                            <Route
                              exact
                              path='/my/subscription'
                              element={
                                <Subscription currentUser={props.currentUser} />
                              }
                            />
                            <Route
                              exact
                              path='/my/restricted'
                              element={<Restricted />}
                            />
                            <Route
                              exact
                              path='/my/blocked'
                              element={<Blocked />}
                            />
                            {/* <Route exact path="/lists/muted/" element={<Muted />} /> */}
                            <Route
                              exact
                              path='/my/add_user_lists/add_user'
                              element={<AddUser />}
                            />
                            <Route
                              exact
                              path='/lists/:id'
                              element={<AddUserList />}
                            />
                            <Route
                              exact
                              path='/contract'
                              element={<Contract />}
                            />
                            <Route
                              exact
                              path='/my/friends'
                              element={<Friends />}
                            />
                            <Route
                              exact
                              path='/my/friends/add_user'
                              element={<AddUserFriends />}
                            />
                            <Route
                              exact
                              path='/vaults'
                              element={<Category />}
                            />
                            <Route
                              exact
                              path='/vaults/list/:id'
                              element={<Post />}
                            />
                            <Route
                              exact
                              path='/my/bookmarks'
                              element={<MyBookmark />}
                            />
                            <Route
                              exact
                              path='/my/bookmarks/add'
                              element={<AddUserBookmark />}
                            />
                            <Route
                              exact
                              path='/my/close_friends'
                              element={<CloseFriends />}
                            />
                            <Route
                              exact
                              path='/my/close_friends/add_user'
                              element={<AddCloseFriendUser />}
                            />
                            <Route
                              exact
                              path='/statistics'
                              element={<Statistics />}
                            />
                            <Route
                              exact
                              path='/my/restricted'
                              element={<Restricted />}
                            />
                            <Route
                              exact
                              path='/notification'
                              element={<Notification />}
                            />
                            <Route
                              exact
                              path='/my/blocked'
                              element={<Blocked />}
                            />
                            <Route
                              exact
                              path='/lists/:id'
                              element={<ViewDetails />}
                            />
                            <Route
                              exact
                              path='/my/add_user_lists/add_user'
                              element={<AddUser />}
                            />
                            <Route
                              exact
                              path='/my/add_user_lists/:id'
                              element={<AddUserList />}
                            />
                            <Route
                              exact
                              path='/my/friends'
                              element={<Friends />}
                            />
                            <Route
                              exact
                              path='/my/friends/add_user'
                              element={<AddUserFriends />}
                            />
                            {/* <Route exact path="/vaults" render={() => <Vault />} /> */}

                            <Route
                              exact
                              path='/collection'
                              element={<Collection />}
                            />

                            <Route
                              exact
                              path='/post_queue'
                              element={<QueueCalendar />}
                            />
                            <Route
                              exact
                              path='/stream'
                              element={<LiveStream />}
                            />
                            <Route
                              exact
                              path='/queue'
                              element={<Queue />}
                            />
                            <Route
                              exact
                              path='/mass_chats'
                              element={<MassMessagesStatistics />}
                            />
                            <Route
                              exact
                              path='/save_for_later'
                              element={<SaveForLatter />}
                            />
                            <Route
                              exact
                              path='/2257'
                              element={<USC />}
                            />
                            <Route
                              exact
                              path='/PostLabels/index'
                              element={<PostLabels />}
                            />
                          </>
                        ) : (
                          <>
                            {/* <Redirect to="/" /> */}
                            {/*   <TalentSignIn />  */}
                          </>
                        )}
                      </Routes>
                    </Suspense>
                  </SidebarV2>
                )}
              </GlobalContext.Provider>
            </GeneralProvider>
          </BrowserRouter>
        </Provider>
      </div>
    </ThemeProvider>
  );
};

export default App;

const ignoreListRoutes = [];
