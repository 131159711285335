import consumer from "./consumer"

consumer.subscriptions.create("MessageChannel", {
  connected() {
    // console.log("message channel connected");
  },

  disconnected() {
    // console.log("message channel disconected ");
  },

  received(data) {
    // console.log("ssssssssssssssssssssssss", data);
    // Called when there's incoming data on the websocket for this channel
  }
});
