import React from "react";
import "./loginPageFooter.scss";
const LoginPageFooter = () => {
 return (
    <div  className="login-footer-root">
  <ul>
   <li>
    <a href="#">About</a>
   </li>
   <li>
    <a href="/terms"> Terms of Service </a>
   </li>
   <li>
    <a href="#">Branding</a>
   </li>
   <li>
    <a href="/privacy"> Privacy </a>
   </li>
   <li>
    <a href="/cookie-notice"> Cookie Notice </a>
   </li>
   <li>
    <a href="#"> Complaints Policy </a>
   </li>
   <li>
    <a href="#">Blog</a>
   </li>
   <li>
    <a href="#">Branding</a>
   </li>
   <li>
    <a href="#"> Standard Contract between Fan and Creator </a>
   </li>
  </ul>
    </div>

 );
};
export default LoginPageFooter;
