import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import axios from "axios";
import List from "@material-ui/core/List";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import logoImg from "../../image/smokeshow.png";
import smokeicon from "../../image/smokeicon.png";
import { FaUserPlus } from "react-icons-all-files/fa/FaUserPlus";
import { MdNotifications } from "react-icons-all-files/md/MdNotifications";
import { ImStatsDots } from "react-icons-all-files/im/ImStatsDots";
import CloseIcon from "@material-ui/icons/Close";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import {
 AccountBalance,
 AccountCircleOutlined,
 HomeOutlined,
 InboxOutlined,
 ListAltOutlined,
 MessageOutlined,
 PeopleAltOutlined,
 QueueOutlined,
 SettingsOutlined,
 VideoCallOutlined,
 RssFeedOutlined,
 AccountBalanceWalletOutlined,
} from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { mediaQuery } from "../hooks/mediaQuery";
import { BsBookmarkStar } from "react-icons-all-files/bs/BsBookmarkStar";
import { Avatar, IconButton, ListItemAvatar } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import WorkIcon from "@material-ui/icons/Work";
import defaultImg from "../../image/avatar_default.png";
import { RiSearchLine } from "react-icons-all-files/ri/RiSearchLine";

const drawerWidth = 312;

let SidebarList = [
 { path: "/", name: "For you", icon: <HomeOutlined />, isFrequent: true },
 { path: "/feed", name: "Feed", icon: <RssFeedOutlined />, isFrequent: true },
 {
  path: "/search",
  name: "Explore",
  icon: <RiSearchLine size={24} />,
  isFrequent: true,
 },
 // {
 //   path: "/following",
 //   name: "Following",
 //   icon: <PeopleAltOutlined />,
 //   isFrequent: true,
 // },
 {
  path: "/notification",
  name: "Notification",
  icon: <MdNotifications className='h4' />,
  isFrequent: true,
 },
 {
  path: "/my/subscription",
  name: "Subscription",
  icon: <FaUserPlus className='h4' />,
  isFrequent: false,
 },
 {
  path: "/live",
  name: "Live",
  icon: <VideoCallOutlined />,
  isFrequent: true,
 },
 {
  path: "/profile",
  name: "My Profile",
  icon: <AccountCircleOutlined />,
  isFrequent: true,
 },
 {
  path: "/messages",
  name: "Messages",
  icon: <MessageOutlined />,
  isFrequent: true,
 },
 {
  path: "/statements",
  name: "Statements",
  icon: <ImStatsDots className='h5' />,
  isFrequent: false,
 },
 {
  path: "/statistics",
  name: "Statistics",
  icon: <ImStatsDots className='h5' />,
  isFrequent: false,
 },
 {
  path: "/collection",
  name: "Collection",
  icon: <BsBookmarkStar className='h5' />,
  isFrequent: false,
 },
 {
  path: "/settings/subscription_price_and_bundles",
  name: "Promotions",
  icon: <BsBookmarkStar className='h5' />,
  isFrequent: false,
 },
 {
  path: "/post_queue",
  name: "Queue",
  icon: <BsBookmarkStar className='h5' />,
  isFrequent: false,
 },
 {
  path: "/statements",
  name: "Referrals",
  icon: <BsBookmarkStar className='h5' />,
  isFrequent: false,
 },
 {
  path: "/payments",
  name: "Wallet",
  icon: <AccountBalanceWalletOutlined />,
  isFrequent: false,
 },
 {
  path: "/vaults",
  name: "Vaults",
  icon: <InboxOutlined />,
  isFrequent: false,
 },
 {
  path: "/lists",
  name: "Lists",
  icon: <ListAltOutlined />,
  isFrequent: false,
 },
 {
  path: "/banking",
  name: "Add Bank",
  icon: <AccountBalance />,
  isFrequent: false,
 },
 {
  path: "/settings",
  name: "Settings",
  icon: <SettingsOutlined />,
  isFrequent: false,
 },
 // {
 //   path: "/post",
 //   name: "New Post",
 //   icon: <QueueOutlined />,
 //   button: true,
 //   isFrequent: true,
 // },
];

if (process.env.ENV_SETTING == "PROD") {
 SidebarList = [
  { path: "/", name: "For you", icon: <HomeOutlined />, isFrequent: true },
  {
   path: "/feed",
   name: "Feed",
   icon: <RssFeedOutlined />,
   isFrequent: true,
  },
  {
   path: "/notification",
   name: "Notification",
   icon: <MdNotifications className='h4' />,
   isFrequent: true,
  },
  {
   path: "/live",
   name: "Live",
   icon: <VideoCallOutlined />,
   isFrequent: true,
  },
  {
   path: "/profile",
   name: "Profile",
   icon: <AccountCircleOutlined />,
   isFrequent: true,
  },
  {
   path: "/messages",
   name: "Messages",
   icon: <MessageOutlined />,
   isFrequent: true,
  },
  {
   path: "/statistics",
   name: "Statistics",
   icon: <ImStatsDots className='h5' />,
   isFrequent: false,
  },
  {
   path: "/payments",
   name: "Wallet",
   icon: <AccountBalanceWalletOutlined />,
   isFrequent: false,
  },
  {
   path: "/vaults",
   name: "Vaults",
   icon: <InboxOutlined />,
   isFrequent: false,
  },
  {
   path: "/lists",
   name: "Lists",
   icon: <ListAltOutlined />,
   isFrequent: true,
  },
  {
   path: "/banking",
   name: "Add Bank",
   icon: <AccountBalance />,
   isFrequent: false,
  },
  {
   path: "/settings",
   name: "Settings",
   icon: <SettingsOutlined />,
   isFrequent: false,
  },
  // {
  //   path: "/post",
  //   name: "New Post",
  //   icon: <QueueOutlined />,
  //   button: true,
  //   isFrequent: true,
  // },
 ];
}

export default function MiniDrawer({
 handleAction,
 currentUser,
 logoutFnc,
 noBurgerIcon,
 toggleDrawer,
}) {
 const classes = useStyles();
 const location = useLocation();
 const history = useNavigate();
 const [isFloatBar, setIsFloatBar] = useState(false);
 const [drawerState, setDrawerState] = useState(true);
 const [notificationCount, setNotificationCount] = useState(null);

 const { isMd, isSm } = mediaQuery();

 // if (!currentUser?.approved) {
 //   SidebarList = SidebarList.filter((arr) => arr.name != "Statistics");
 //   SidebarList = SidebarList.filter((arr) => arr.name != "Statements");
 //   SidebarList = SidebarList.filter((arr) => arr.name != "Vaults");
 //   SidebarList = SidebarList.filter((arr) => arr.name != "Queue");
 //   SidebarList = SidebarList.filter((arr) => arr.name != "Promotions");
 // }

 SidebarList = SidebarList.filter((arr) => arr.name != "Lists");

 const drawerAction = () => {
  setDrawerState(!drawerState);
 };

 const linkOpen = (val) => {
  if (currentUser || val === "/") {
   history(val);
  } else {
   handleAction(true);
  }
  if (toggleDrawer) {
   toggleDrawer(false);
  }
 };

 const logout = () => {
  console.log("LOUGOUT HIT!!!!!!!!!!!!!!!!");
  const token = document.querySelector('meta[name="csrf-token"]').content;
  axios
   .delete("/users/sign_out", {
    headers: {
     "content-type": "application/json",
     "X-CSRF-Token": token,
    },
   })
   .then(function (response) {
    logoutFnc;
    window.location.href = "/";
   })
   .catch(function (error) {
    console.log(error);
   });
 };

 const floatFunc = () => {
  setIsFloatBar(!isFloatBar);
 };

 const activeTabFnc = (path) => (location?.pathname === path ? true : false);
 // settings

 useEffect(() => {
  if (location?.pathname.includes("/settings")) {
   setDrawerState(true);
  } else {
   setDrawerState(true);
  }
 }, [location?.pathname]);

 useEffect(() => {
  getNotificationFnc();
 }, []);

 const getNotificationFnc = async () => {
  await axios
   .get(`/notifications?page=${1}&size=4`, {
    params: {
     view: "all",
    },
   })
   .then(function (response) {
    if (response?.data.unread_count) {
     setNotificationCount(response?.data.unread_count);
    }
   })
   .catch((error) => {
    console.log("error", error);
   });
 };

 return (
  <Drawer
   variant='permanent'
   className={clsx(classes.drawer, {
    [classes.drawerState]: isMd || drawerState,
    [classes.drawerClose]: isMd || !drawerState,
   })}
   classes={{
    paperAnchorLeft: classes.paperAnchorLeft,

    paper: clsx(classes.paper, {
     [classes.drawerState]: isMd || drawerState,
     [classes.drawerClose]: isMd || !drawerState,
     [classes.floatBarRoot]: isFloatBar,
    }),
   }}
   style={{
    position: shouldIgnoreRoute(location?.pathname) ? "relative" : "relative",
   }}>
   {isFloatBar ? (
    <>
     <div style={{ paddingTop: "9px", position: "relative" }}>
      {/* {!drawerState ? null : ( */}
      <IconButton
       color='inherit'
       aria-label='open drawer'
       onClick={() => setIsFloatBar(false)}
       edge='start'
       style={{
        position: "absolute",
        top: "3px",
        right: "3px",
        color: "#757575",
        zIndex: "2",
       }}
       className={clsx(classes.menuButton, {
        //   [classes.hide]: open,
       })}>
       <CloseIcon />
      </IconButton>
      {/* )} */}
     </div>
     <div>
      {/* {window.location.pathname == "/settings" ? (
              <div style={{ marginLeft: "13px", marginBottom: "10px" }}>
                {" "}
                <img
                  src={defaultImg}
                  style={{ width: "30px" }}
                  alt=''
                  className='rounded-circle'
                />
              </div>
            ) : ( */}
      <UserTab />
      {/* )} */}
     </div>
     <hr style={{ margin: "0", marginBottom: "2px" }} />
    </>
   ) : (
    <>
     <div style={{ marginTop: "10px", position: "relative" }}></div>
     {/* <div className={classes.toolbar}> */}
     {/* {noBurgerIcon ? null : (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={drawerAction}
              edge='start'
              className={clsx(classes.menuButton, {
                //   [classes.hide]: open,
              })}>
              <MenuIcon />
            </IconButton>
          )} */}
     {/* <img
              src={logoImg}
              alt='logo'
              width={200}
              className='logoImg-sidedrawer-style'
            /> */}
     {/* </div> */}
    </>
   )}

   <List className={classes.ul}>
    <ListItem className={classes.miniBarSubRoot}>
     <List className={classes.ul}>
      {SidebarList?.length &&
       SidebarList.map((tab, index) =>
        tab?.path === "/feed" && !currentUser ? null : isFloatBar &&
          !tab.isFrequent ? (
         <ListItem
          key={index}
          button
          onClick={() => linkOpen(tab?.path)}
          selected={activeTabFnc(tab?.path)}
          className={clsx(
           tab?.button ? "_tabButton" : "",
           classes.listItemStyle
          )}>
          {tab?.icon ? <ListItemIcon>{tab?.icon}</ListItemIcon> : null}
          <ListItemText
           className={classes.text}
           primary={tab?.name}
          />
         </ListItem>
        ) : !isFloatBar && tab.isFrequent ? (
         <ListItem
          key={index}
          button
          onClick={() => linkOpen(tab?.path)}
          selected={activeTabFnc(tab?.path)}
          className={clsx(
           tab?.button ? "_tabButton" : "",
           classes.listItemStyle
          )}>
          {tab?.icon ? <ListItemIcon>{tab?.icon}</ListItemIcon> : null}
          <ListItemText
           className={classes.text}
           primary={tab?.name}
          />
          {tab?.path === "/notification" && notificationCount ? (
           <ListItemText
            className={classes.noti_icon}
            primary={notificationCount}
           />
          ) : null}
         </ListItem>
        ) : null
       )}
      {currentUser ? (
       <ListItem
        button
        onClick={floatFunc}
        className={clsx(classes.listItemStyle)}>
        <ListItemIcon>
         <MoreHorizIcon
          style={{
           border: "2px solid #757575",
           borderRadius: "50%",
          }}
         />
        </ListItemIcon>
        <ListItemText
         className={classes.text}
         primary={isFloatBar ? "Less" : "More"}
        />
       </ListItem>
      ) : null}
      {isFloatBar ? (
       <>
        <ListItem
         button
         onClick={() => logout()}
         className={clsx("_tabButton", classes.listItemStyle)}
         style={{ marginTop: "20px" }}>
         <ListItemIcon>
          <ExitToAppIcon />
         </ListItemIcon>
         <ListItemText
          className={classes.text}
          primary={"Logout"}
         />
        </ListItem>
       </>
      ) : (
       <>
        {/* <ListItem
                  button
                  onClick={() => drawerAction()}
                  className={clsx(classes.listItemStyle)}>
                  <ListItemIcon>
                    {drawerState ? (
                      <ArrowBackIcon />
                    ) : (
                      <ArrowForwardIosIcon ƒ />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    className={classes.text}
                    primary={"Collapse"}
                  />
                </ListItem> */}
        {currentUser ? (
         <ListItem
          button
          onClick={() => linkOpen("/post")}
          selected={activeTabFnc("/post")}
          className={clsx("_tabButton", classes.listItemStyle)}>
          <ListItemIcon>
           <QueueOutlined />
          </ListItemIcon>
          <ListItemText
           className={classes.text}
           primary={"New Post"}
          />
         </ListItem>
        ) : (
         <ListItem
          button
          onClick={() => handleAction(true)}
          className={clsx("_tabButton", classes.listItemStyle)}>
          <ListItemIcon>
           <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText
           className={classes.text}
           primary={"Login / Signup"}
          />
         </ListItem>
        )}
       </>
      )}
     </List>
     {drawerState && !isMd && !isFloatBar ? (
      <List style={{ textAlign: "center" }}>
       {/* <img
                src={logoImg}
                width={130}
                style={{ marginButton: "7px" }}
              /> */}
       <Box
        style={{
         display: "flex",
         padding: "10px",
         paddingBottom: "30px",
         gap: "0px",
         flexWrap: "wrap",
         justifyContent: "center",
        }}>
        <Link to='/privacy'>
         <Typography
          variant='body2'
          style={{
           color: "#a4a4a4",
           fontWeight: "400",
           fontSize: "11px",
          }}>
          Privacy & Policy &nbsp;
         </Typography>
        </Link>
        <Link to='/terms'>
         <Typography
          variant='body2'
          style={{
           color: "#a4a4a4",
           fontWeight: "400",
           fontSize: "11px",
          }}>
          Terms of Service
         </Typography>
        </Link>
        <Link
         to=''
         style={{ width: "100%" }}>
         <Typography
          variant='body2'
          style={{
           color: "#a4a4a4",
           fontWeight: "600",
           fontSize: "11px",
           marginTop: "2px",
          }}>
          © Copyright 2023 - All rights are reserved
         </Typography>
        </Link>
       </Box>
      </List>
     ) : null}
    </ListItem>
   </List>
   {/* <FloatingSidebar /> */}
  </Drawer>
 );
}

const UserTab = () => {
 const classes = useStyles();
 return (
  <List className={classes.ul}>
   <ListItem className={clsx(classes.listItemStyle)}>
    <ListItemIcon>
     <Link to='/profile'>
      <img
       src={defaultImg}
       style={{ width: "48px" }}
       alt=''
       className='rounded-circle'
      />
     </Link>
    </ListItemIcon>
    <ListItemText
     primary={
      <div>
       Seema A.{" "}
       <Link to='/settings/profile'>
        <SettingsIcon />{" "}
       </Link>
      </div>
     }
     secondary='@seema.aly'
    />
   </ListItem>
  </List>
 );
};

const useStyles = makeStyles((theme) => ({
 floatBarRoot: {
  backgroundColor: "white",
  boxShadow: "1px 1px 26px -4px #00000063",
  position: "fixed",
  height: "97%",
  width: "312px",
  top: "8px",
  overflow: "hidden",
  borderRadius: "8px",
 },
 noti_icon: {
  position: "absolute",
  left: "29px",
  top: "5px",
  background: " #fe2c55",
  height: "17px",
  width: "17px",
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& span": {
   fontSize: "10px",
   color: "white",
  },
 },
 root: {
  display: "flex",
  "& .MuiDrawer-root:after": {
   content: "''",
   position: "relative",
   right: "0px",
   top: "0px",
   height: "20px",
   width: "20px",
   backgroundColor: "red",
  },
 },
 text: {
  "& span": {
   fontSize: "18px",
  },
 },
 h3: {
  color: "red",
 },
 ul: {
  marginTop: "0",
  padding: 0,
  border: 0,
  // borderRight: "1px solid #e2e2e2",
  // display: "none",
 },
 paperAnchorLeft: {
  left: "auto",
  right: "auto",
 },
 appBar: {
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
   easing: theme.transitions.easing.sharp,
   duration: theme.transitions.duration.leavingScreen,
  }),
 },
 miniBarSubRoot: {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: 0,
  height: "calc(100vh - 0px)",
  overflowY: "auto",
  overflowX: "hidden",
  "& .MuiList-root": {
   width: "100%",
   border: 0,
  },
  "& .MuiListItemIcon-root": {
   minWidth: "42px",
  },
  "& ._tabButton": {
   marginTop: "20px",
   background: "#fe2c55",
   borderRadius: "8px",
   margin: "auto",
   width: "94%",
   color: "white",
   "& svg": {
    color: "white",
   },
  },
 },
 appBarShift: {
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(["width", "margin"], {
   easing: theme.transitions.easing.sharp,
   duration: theme.transitions.duration.enteringScreen,
  }),
 },
 menuButton: {
  marginLeft: "-4px",
  top: "8px",
 },
 hide: {
  display: "none",
 },
 paper: {
  overflow: "hidden",
  border: "none",
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.11)",
  // borderLeft: "1px solid #e2e2e2 !important",
 },
 floatBar: {
  height: "90%",
  border: "none !important",
  boxShadow: "1px 1px 10px 10px red",
 },
 drawer: {
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  overflow: "hidden",
 },
 drawerState: {
  width: drawerWidth,
  // boxShadow: "0px 0px 8px rgb(0 0 0 / 11%)",
  border: 0,
  "&:after": {
   content: "",
   position: "relative",
   right: 0,
   left: 0,
   top: 0,
   height: "20px",
   width: "20px",
   backgroundColor: "red",
  },
  transition: theme.transitions.create("width", {
   easing: theme.transitions.easing.sharp,
   duration: theme.transitions.duration.enteringScreen,
  }),
 },
 drawerClose: {
  // boxShadow: "0px 0px 8px rgb(0 0 0 / 11%)",
  border: 0,
  "&:after": {
   content: "",
   position: "relative",
   right: 0,
   left: 0,
   top: 0,
   height: "20px",
   width: "20px",
   backgroundColor: "red",
  },
  transition: theme.transitions.create("width", {
   easing: theme.transitions.easing.sharp,
   duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "60px",
  [theme.breakpoints.up("sm")]: {
   width: "60px",
  },
 },
 toolbar: {
  // display: "none",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  minHeight: "63px !important",
 },
 content: {
  flexGrow: 1,
  [theme.breakpoints.up("sm")]: {
   paddingTop: "64px !important",
  },
 },
 listItemStyle: {
  borderRadius: "7px",
  width: "94%",
  margin: "auto",
 },
}));

const shouldIgnoreRoute = (path) => {
 const pattern = /^\/messages\/\d+$/;
 return pattern.test(path) || ignoreRoutes.includes(path);
};

const ignoreRoutes = [
 "/messages",
 "/save_for_later",
 "/my/subscription",
 "/vaults",
 "/live",
 "/collection",
 "/collections",
 "/messages/new",
 "/lists",
 "/banking",
 "/privacy",
 "/cookie-notice",
 "/terms",
 "/settings",
 "/post_queue",
 "/settings/profile",
 "/settings/my_cards",
 "/settings/account/username",
 "/settings/account/phone",
 "/settings/account/email",
 "/settings/account/twiter",
 "/settings/account/gmail",
 "/settings/account/connect_account",
 "/settings/account/password",
 "/settings/account/window-hello",
 "/settings/account/session",
 "/settings/account/two-step-authentiation",
 "/settings/post/post_details",
 "/settings/account/delete_account",
 "/settings/account",
 "/settings/my_subscriptions",
 "/settings/subscription_price_and_bundles",
 "/settings/privacy_and_safety",
 "/settings/privacy_and_safety/block_by_ip",
 "/settings/privacy_and_safety/block_by_country",
 "/settings/privacy_and_safety/watermarks",
 "/settings/privacy_and_safety/restrict_account",
 "/settings/privacy_and_safety/block_account",
 "/settings/fans",
 "/settings/what_is_new",
 "/settings/display",
 "/settings/display/language",
 "/settings/story",
 "/settings/qr_code",
 "/settings/chats",
 "/settings/subscription-history",
 "/settings/chats/restricted",
 "/settings/notification",
 "/settings/notification/restricted",
 "/settings/notification/webpush",
 "/settings/notification/email",
 "/settings/notification/site",
 "/settings/notification/toast",
 "/settings/notification/sms",
 "/settings/notification/telegram",
 "/settings/promotions",
];
